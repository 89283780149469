import {Connection, LAMPORTS_PER_SOL, PublicKey} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import {BN, Provider} from "@project-serum/anchor";
import {
  CANDY_MACHINE_PROGRAM_V2_ID,
  CIVIC,
  SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, TOKEN_METADATA_PROGRAM_ID,
  TOKEN_PROGRAM_ID
} from "./constants";
import {Collection} from "../supabase/models";
import {WalletContextState} from "@solana/wallet-adapter-react";
import {CandyMachineData, WhitelistMintMode} from "./types";

export function uuidFromConfigPublicKey(configAccount: PublicKey) {
  return configAccount.toBase58().slice(0, 6);
}

export async function loadCandyProgramV2(wallet: any, connection: Connection) {
  const provider = new Provider(connection, wallet, {
    preflightCommitment: 'recent',
  });
  const idl = await anchor.Program.fetchIdl(
    CANDY_MACHINE_PROGRAM_V2_ID,
    provider,
  );
  const program = new anchor.Program(
    idl!,
    CANDY_MACHINE_PROGRAM_V2_ID,
    provider,
  );
  return program;
}

export const getAtaForMint = async (
  mint: PublicKey,
  buyer: PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [buyer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
    SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
  );
};

export const getNetworkToken = async (
  wallet: anchor.web3.PublicKey,
  gatekeeperNetwork: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [
      wallet.toBuffer(),
      Buffer.from('gateway'),
      Buffer.from([0, 0, 0, 0, 0, 0, 0, 0]),
      gatekeeperNetwork.toBuffer(),
    ],
    CIVIC
  );
};

export const getNetworkExpire = async (
  gatekeeperNetwork: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [gatekeeperNetwork.toBuffer(), Buffer.from('expire')],
    CIVIC
  );
};

export const getMetadata = async (
  mint: PublicKey,
): Promise<PublicKey> => {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID,
    )
  )[0];
};

export const getMasterEdition = async (
  mint: PublicKey,
): Promise<PublicKey> => {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from('edition'),
      ],
      TOKEN_METADATA_PROGRAM_ID,
    )
  )[0];
};

export const getCandyMachineCreator = async (
  candyMachine: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from('candy_machine'), candyMachine.toBuffer()],
    CANDY_MACHINE_PROGRAM_V2_ID,
  );
};

export const getCollectionPDA = async (
  candyMachineAddress: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from('collection'), candyMachineAddress.toBuffer()],
    CANDY_MACHINE_PROGRAM_V2_ID,
  );
};

export const getCollectionAuthorityRecordPDA = async (
  mint: anchor.web3.PublicKey,
  newAuthority: anchor.web3.PublicKey,
): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from('collection_authority'),
        newAuthority.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getUnixTs = () => {
  return new Date().getTime() / 1000;
};

export const toDate = (value?: anchor.BN) => {
  if (!value) {
    return;
  }

  return new Date(value.toNumber() * 1000);
};

export const toDateString = (value?: anchor.BN) => {
  if (!value) {
    return;
  }

  const date = new Date(value.toNumber() * 1000);
  return date.toLocaleString();
};

export const getErrorForTransaction = async (
  connection: Connection,
  txid: string,
) => {
  // wait for all confirmation before geting transaction
  await connection.confirmTransaction(txid, 'max');

  const tx = await connection.getParsedConfirmedTransaction(txid);

  const errors: string[] = [];
  if (tx?.meta && tx.meta.logMessages) {
    tx.meta.logMessages.forEach(log => {
      const regex = /Error: (.*)/gm;
      let m;
      while ((m = regex.exec(log)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        if (m.length > 1) {
          errors.push(m[1]);
        }
      }
    });
  }

  return errors;
};

export function toCandyMachineData(collection: Collection, wallet: WalletContextState): CandyMachineData {
  let whitelistMintSettings: null | {
    mode: WhitelistMintMode;
    mint: PublicKey;
    presale: boolean;
    discountPrice: null | anchor.BN;
  } = null;

  if (collection.whitelist_enabled) {
    whitelistMintSettings = {
      //@ts-ignore
      mode: collection.whitelist_mode?.toLowerCase() === 'burn every time' ?
        {
          burnEveryTime: true
        }
      :
        {
          neverBurn: true,
        },
      mint: new PublicKey(collection.whitelist_mint!),
      presale: collection.whitelist_presale,
      discountPrice: (collection.whitelist_discount_price ?? 0) > 0 ?
        new BN(collection.whitelist_discount_price! * LAMPORTS_PER_SOL)
        : null
    }
  }


  return {
    itemsAvailable: new BN(collection.supply),
    uuid: null,
    symbol: collection.symbol,
    sellerFeeBasisPoints: collection.seller_fee_basis_points,
    isMutable: true,
    maxSupply: new BN(0),
    retainAuthority: true,
    gatekeeper: collection.captcha_enabled ? {
        gatekeeperNetwork: new PublicKey("ignREusXmGrscGNUesoU9mxfds9AiYTezUKex2PsZV6"),
        expireOnUse: true
      }
      : null,
    goLiveDate: new BN(parseDate(collection.go_live_date)),
    price: new BN(collection.price * LAMPORTS_PER_SOL),
    endSettings: {
      endSettingType: {amount: true},
      number: new BN(collection.supply)
    },
    whitelistMintSettings: whitelistMintSettings,
    hiddenSettings: null,
    creators: [
      {
        address: wallet.publicKey!,
        verified: true,
        share: 100,
      }
    ],
  }
}

export function parseDate(dateString: string) {
  if (dateString === 'now') {
    return Date.now() / 1000;
  }
  return Date.parse(dateString) / 1000;
}
