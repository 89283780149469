export default function Footer() {
  return (
    <div className="container mt-5">
      <footer className="d-flex flex-wrap justify-content-between align-items-start py-3 my-4 border-top">
        <div className="col-md-6 mb-0 text-muted">
          <div className="row">
            <p>
              ⚡️ Powered by <a className="text-muted" href="https://www.metaplex.com/">Metaplex</a>
              &nbsp;and <a className="text-muted" href="https://www.arweave.org/">Arweave</a>
            </p>
          </div>
          <div className="row">
            <p>❤️ Brought to you by <a className="text-muted" href="https://www.theremaindersnft.com/">The Remainders</a> team</p>
          </div>
        </div>

        <ul className="nav col-md-6 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a className="text-muted" href="https://blog.sorbet.studio/">
              <i className="bi-blockquote-left fs-3" />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-muted" href="https://discord.gg/qZcPwyhR">
              <i className="bi bi-discord fs-3" />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-muted" href="https://twitter.com/Sorbet_Studio">
              <i className="bi bi-twitter fs-3" />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
}
