import {Connection, Keypair} from "@solana/web3.js";
import {WalletContextState} from "@solana/wallet-adapter-react";
import {AssetWithTransaction, Collection} from "../supabase/models";
import {ConfigLineUploadHandler, createCandyMachineV2, writeIndices} from "./create";
import {addCandyMachine} from "../supabase";
import {toCandyMachineData} from "./utils";

export async function createSorbetCandyMachine(connection: Connection, wallet: WalletContextState,
                                               collection: Collection) {
  let candyMachineData = toCandyMachineData(collection, wallet);
  return await createCandyMachineV2(connection, wallet, wallet.publicKey!, null, candyMachineData);
}

export async function uploadToCandyMachine(connection: Connection, wallet: WalletContextState, collection: Collection,
                                           assetsWithTransactions: AssetWithTransaction[], candyMachine: Keypair,
                                           onAssetUploaded?: ConfigLineUploadHandler) {
  await writeIndices(connection, assetsWithTransactions, candyMachine, wallet, onAssetUploaded);

  await addCandyMachine({
    collection_id: collection.id!,
    nickname: collection.name,
    address: candyMachine.publicKey.toBase58()
  });
}
