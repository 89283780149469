import {Trait} from "../lib/supabase/models";
import {useState} from "react";

type OnRarityChangedArgs = {
  trait: Trait,
  rarity: number
}

type Props = {
    trait: Trait;
    onRarityChange?: (args: OnRarityChangedArgs) => void
}

function roundAndClamp(numberString: string) {
  const minValue = 0;
  const maxValue = 100;
  if (!numberString) return 0;
  const roundedValue = Number((parseFloat(numberString)).toFixed(2));
  const valueClampedLow = roundedValue < minValue ? minValue : roundedValue;
  const valueClampedHigh = valueClampedLow > maxValue ? maxValue : valueClampedLow;
  return valueClampedHigh;
}


function TraitImageCard(props: Props) {
  const [error, setError] = useState<string>();

  function validate(numberString: string) {
    const parsedNumber = parseFloat(numberString);
    // if (!parsedNumber && parsedNumber !== 0) setError('Required');
    if (parsedNumber < 0 || parsedNumber > 100) setError('Must be a number between 0 and 100');
    setError('');
  }

  return (
    <div className="card border border-dark border-2 shadow-box" style={{backgroundColor: "#f5fff1"}}>
      <img className="card-img-top" src={props.trait.object_url} alt={`${props.trait.value}`}></img>
      <div className="card-body" style={{backgroundColor: "#f5fff1"}}>
        <p className="card-title">{props.trait.value}</p>

        <div className="input-group">
          <input className="form-control" type="number" aria-describedby="percent-addon"
                 value={props.trait.rarity * 100} min={0} max={100} step={5}
                 onChange={e => {
                   validate(e.target.value);
                   const clampedValue = roundAndClamp(e.target.value);
                   props.onRarityChange && props.onRarityChange({
                     trait: props.trait,
                     rarity: clampedValue
                   })
                 }}
          />
          <span className="input-group-text" id="percent-addon">%</span>
        </div>
        {error &&
            <p className="text-danger p-2">{error}</p>
        }
      </div>
    </div>
  )
}

export default TraitImageCard
