import {CandyMachine} from "./models";
import {definitions} from "./types";
import {supabase} from "./supabase";

export const addCandyMachine = async (candyMachine: CandyMachine) => {
  const {data} = await supabase
    .from<definitions["candy_machines"]>("candy_machines")
    .insert(candyMachine);

  return data![0].id;
}

export const getCandyMachine = async (collectionId: number) => {
  const {data} = await supabase
    .from<definitions["candy_machines"]>("candy_machines")
    .select("*")
    .match({collection_id: collectionId});

  if (data && data.length > 0) {
    return data[0];
  }
}

export const getCandyMachineByAddress = async (address: string) => {
  const {data} = await supabase
    .from<definitions["candy_machines"]>("candy_machines")
    .select("*")
    .match({address: address});

  if (data && data.length > 0) {
    return data[0];
  }
}
