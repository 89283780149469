import {getCollections} from "../lib/supabase";
import {useEffect, useState} from "react";
import {Collection} from "../lib/supabase/models";
import {Link} from "react-router-dom";

export function Collections() {

  const [collections, setCollections] = useState<Collection[]>([]);

  useEffect(() => {
    async function get() {
      const collections = await getCollections();
      setCollections(collections);
    };
    get();
  }, []);

  return (
    <div className="container mb-5 text-center">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8 col-xl-6">
          <h1>Collections</h1>
          <Link className="link-unstyled" to="new">
            <div className="btn btn-success my-4">Create</div>
          </Link>
          {collections.map(collection =>
            <Link key={collection.id} className="link-unstyled" to={`${collection.id}`}>
              <div className="card m-3 btn bg-secondary">
                <div className="card-body">
                  <h5 className="card-title">{collection.name}</h5>
                  <div className="row">
                    <div className="col">Supply: {collection.supply}</div>
                    <div className="col">Price: {collection.price}◎</div>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
