import {
  Commitment,
  Connection,
  Keypair,
  PublicKey, SignatureStatus,
  SystemProgram,
  SYSVAR_CLOCK_PUBKEY, SYSVAR_INSTRUCTIONS_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  SYSVAR_SLOT_HASHES_PUBKEY,
  Transaction, TransactionInstruction, TransactionSignature
} from "@solana/web3.js";
import {CandyMachineAccount, CollectionData} from "./types";
import {
  getAtaForMint,
  getCandyMachineCreator, getCollectionAuthorityRecordPDA,
  getCollectionPDA,
  getMasterEdition,
  getMetadata,
  getNetworkExpire,
  getNetworkToken, sleep
} from "./utils";
import {
  createApproveInstruction,
  createAssociatedTokenAccountInstruction, createInitializeMintInstruction,
  createMintToInstruction, createRevokeInstruction,
  MintLayout,
  TOKEN_PROGRAM_ID
} from '@solana/spl-token';
import {WalletContextState} from "@solana/wallet-adapter-react";
import {CIVIC, TOKEN_METADATA_PROGRAM_ID} from "./constants";
import {sendTransactions, SequenceType} from "./sendTransactions";

export const mintOneToken = async (
  connection: Connection,
  wallet: WalletContextState,
  candyMachine: CandyMachineAccount,
  beforeTransactions: Transaction[] = [],
  afterTransactions: Transaction[] = [],
): Promise<(string | undefined)[]> => {
  const walletPubKey = wallet.publicKey!;

  const mint = Keypair.generate();

  const userTokenAccountAddress = (
    await getAtaForMint(mint.publicKey, walletPubKey)
  )[0];

  const userPayingAccountAddress = candyMachine.state.tokenMint
    ? (await getAtaForMint(candyMachine.state.tokenMint, walletPubKey))[0]
    : walletPubKey;

  const candyMachineAddress = candyMachine.id;

  const remainingAccounts = [];
  const signers: Keypair[] = [mint];
  const cleanupInstructions = [];

  const instructions = [
    SystemProgram.createAccount({
      fromPubkey: walletPubKey,
      newAccountPubkey: mint.publicKey,
      space: MintLayout.span,
      lamports:
        await candyMachine.program.provider.connection.getMinimumBalanceForRentExemption(
          MintLayout.span,
        ),
      programId: TOKEN_PROGRAM_ID,
    }),
    createInitializeMintInstruction(mint.publicKey, 0, walletPubKey, walletPubKey),
    createAssociatedTokenAccountInstruction(walletPubKey, userTokenAccountAddress, walletPubKey, mint.publicKey),
    createMintToInstruction(mint.publicKey, userTokenAccountAddress, walletPubKey, 1, [])
  ];

  if (candyMachine.state.gatekeeper) {
    remainingAccounts.push({
      pubkey: (
        await getNetworkToken(
          walletPubKey,
          candyMachine.state.gatekeeper.gatekeeperNetwork,
        )
      )[0],
      isWritable: true,
      isSigner: false,
    });

    if (candyMachine.state.gatekeeper.expireOnUse) {
      remainingAccounts.push({
        pubkey: CIVIC,
        isWritable: false,
        isSigner: false,
      });
      remainingAccounts.push({
        pubkey: (
          await getNetworkExpire(
            candyMachine.state.gatekeeper.gatekeeperNetwork,
          )
        )[0],
        isWritable: false,
        isSigner: false,
      });
    }
  }
  if (candyMachine.state.whitelistMintSettings) {
    const mint = new PublicKey(
      candyMachine.state.whitelistMintSettings.mint,
    );

    const whitelistToken = (await getAtaForMint(mint, walletPubKey))[0];
    remainingAccounts.push({
      pubkey: whitelistToken,
      isWritable: true,
      isSigner: false,
    });

    if (candyMachine.state.whitelistMintSettings.mode.burnEveryTime) {
      const whitelistBurnAuthority = Keypair.generate();

      remainingAccounts.push({
        pubkey: mint,
        isWritable: true,
        isSigner: false,
      });
      remainingAccounts.push({
        pubkey: whitelistBurnAuthority.publicKey,
        isWritable: false,
        isSigner: true,
      });
      signers.push(whitelistBurnAuthority);
      const exists =
        await candyMachine.program.provider.connection.getAccountInfo(
          whitelistToken,
        );
      if (exists) {
        instructions.push(
          createApproveInstruction(
            whitelistToken,
            whitelistBurnAuthority.publicKey,
            walletPubKey,
            1,
            [],
          ),
        );
        cleanupInstructions.push(
          createRevokeInstruction(
            whitelistToken,
            walletPubKey,
            [],
          ),
        );
      }
    }
  }

  if (candyMachine.state.tokenMint) {
    const transferAuthority = Keypair.generate();

    signers.push(transferAuthority);
    remainingAccounts.push({
      pubkey: userPayingAccountAddress,
      isWritable: true,
      isSigner: false,
    });
    remainingAccounts.push({
      pubkey: transferAuthority.publicKey,
      isWritable: false,
      isSigner: true,
    });

    instructions.push(
      createApproveInstruction(
        userPayingAccountAddress,
        transferAuthority.publicKey,
        walletPubKey,
        candyMachine.state.price.toNumber(),
        [],
      ),
    );
    cleanupInstructions.push(
      createRevokeInstruction(
        userPayingAccountAddress,
        walletPubKey,
        [],
      ),
    );
  }
  const metadataAddress = await getMetadata(mint.publicKey);
  const masterEdition = await getMasterEdition(mint.publicKey);

  const [candyMachineCreator, creatorBump] = await getCandyMachineCreator(
    candyMachineAddress,
  );

  console.log(remainingAccounts.map(rm => rm.pubkey.toBase58()));
  instructions.push(
    await candyMachine.program.instruction.mintNft(creatorBump, {
      accounts: {
        candyMachine: candyMachineAddress,
        candyMachineCreator,
        payer: walletPubKey,
        wallet: candyMachine.state.treasury,
        mint: mint.publicKey,
        metadata: metadataAddress,
        masterEdition,
        mintAuthority: walletPubKey,
        updateAuthority: walletPubKey,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
        clock: SYSVAR_CLOCK_PUBKEY,
        recentBlockhashes: SYSVAR_SLOT_HASHES_PUBKEY,
        instructionSysvarAccount: SYSVAR_INSTRUCTIONS_PUBKEY,
      },
      remainingAccounts:
        remainingAccounts.length > 0 ? remainingAccounts : undefined,
    }),
  );

  const [collectionPDA] = await getCollectionPDA(candyMachineAddress);
  const collectionPDAAccount =
    await candyMachine.program.provider.connection.getAccountInfo(
      collectionPDA,
    );

  if (collectionPDAAccount && candyMachine.state.retainAuthority) {
    try {
      const collectionData =
        (await candyMachine.program.account.collectionPda.fetch(
          collectionPDA,
        )) as CollectionData;
      console.log(collectionData);
      const collectionMint = collectionData.mint;
      const collectionAuthorityRecord = await getCollectionAuthorityRecordPDA(
        collectionMint,
        collectionPDA,
      );
      console.log(collectionMint);
      if (collectionMint) {
        const collectionMetadata = await getMetadata(collectionMint);
        const collectionMasterEdition = await getMasterEdition(collectionMint);
        console.log('Collection PDA: ', collectionPDA.toBase58());
        console.log('Authority: ', candyMachine.state.authority.toBase58());
        instructions.push(
          await candyMachine.program.instruction.setCollectionDuringMint({
            accounts: {
              candyMachine: candyMachineAddress,
              metadata: metadataAddress,
              payer: walletPubKey,
              collectionPda: collectionPDA,
              tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
              instructions: SYSVAR_INSTRUCTIONS_PUBKEY,
              collectionMint,
              collectionMetadata,
              collectionMasterEdition,
              authority: candyMachine.state.authority,
              collectionAuthorityRecord,
            },
          }),
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const instructionsMatrix: TransactionInstruction[][] = [];
  const signersMatrix: Keypair[][] = [];

  const state = candyMachine.state;
  const txnEstimate =
    892 +
    (collectionPDAAccount && state.retainAuthority ? 182 : 0) +
    (state.tokenMint ? 177 : 0) +
    (state.whitelistMintSettings ? 33 : 0) +
    (state.whitelistMintSettings?.mode?.burnEveryTime ? 145 : 0) +
    (state.gatekeeper ? 33 : 0) +
    (state.gatekeeper?.expireOnUse ? 66 : 0);

  const INIT_INSTRUCTIONS_LENGTH = 4;
  const INIT_SIGNERS_LENGTH = 1;

  console.log('Transaction estimate: ', txnEstimate);
  if (txnEstimate > 1230) {
    const initInstructions = instructions.splice(0, INIT_INSTRUCTIONS_LENGTH);
    console.log(initInstructions);
    instructionsMatrix.push(initInstructions);
    const initSigners = signers.splice(0, INIT_SIGNERS_LENGTH);
    signersMatrix.push(initSigners);
  }

  instructionsMatrix.push(instructions);
  signersMatrix.push(signers);

  if (cleanupInstructions.length > 0) {
    instructionsMatrix.push(cleanupInstructions);
    signersMatrix.push([]);
  }

  try {
    return (
      await sendTransactions(
        candyMachine.program.provider.connection,
        wallet,
        instructionsMatrix,
        signersMatrix,
        SequenceType.StopOnFailure,
        'singleGossip',
        () => {},
        () => false,
        undefined,
        beforeTransactions,
        afterTransactions,
      )
    ).txs.map(t => t.txid);
  } catch (e) {
    console.log(e);
  }

  return [];
};

export async function awaitTransactionSignatureConfirmation(
  txid: TransactionSignature,
  timeout: number,
  connection: Connection,
  commitment: Commitment = 'recent',
  queryStatus = false,
): Promise<SignatureStatus | null | void> {
  let done = false;
  let status: SignatureStatus | null | void = {
    slot: 0,
    confirmations: 0,
    err: null,
  };
  let subId = 0;
  status = await new Promise(async (resolve, reject) => {
    setTimeout(() => {
      if (done) {
        return;
      }
      done = true;
      console.log('Rejecting for timeout...');
      reject({ timeout: true });
    }, timeout);
    try {
      subId = connection.onSignature(
        txid,
        (result, context) => {
          done = true;
          status = {
            err: result.err,
            slot: context.slot,
            confirmations: 0,
          };
          if (result.err) {
            console.log('Rejected via websocket', result.err);
            reject(status);
          } else {
            console.log('Resolved via websocket', result);
            resolve(status);
          }
        },
        commitment,
      );
    } catch (e) {
      done = true;
      console.error('WS error in setup', txid, e);
    }
    while (!done && queryStatus) {
      // eslint-disable-next-line no-loop-func
      (async () => {
        try {
          const signatureStatuses = await connection.getSignatureStatuses([
            txid,
          ]);
          status = signatureStatuses && signatureStatuses.value[0];
          if (!done) {
            if (!status) {
              console.log('REST null result for', txid, status);
            } else if (status.err) {
              console.log('REST error for', txid, status);
              done = true;
              reject(status.err);
            } else if (!status.confirmations) {
              console.log('REST no confirmations for', txid, status);
            } else {
              console.log('REST confirmation for', txid, status);
              done = true;
              resolve(status);
            }
          }
        } catch (e) {
          if (!done) {
            console.log('REST connection error: txid', txid, e);
          }
        }
      })();
      await sleep(2000);
    }
  });

  //@ts-ignore
  if (connection._signatureSubscriptions[subId])
    connection.removeSignatureListener(subId);
  done = true;
  console.log('Returning status', status);
  return status;
}
