import { CandyMachineAccount } from "./types";
import {loadCandyProgramV2} from "./utils";
import {WalletContextState} from "@solana/wallet-adapter-react";
import { Connection, PublicKey } from "@solana/web3.js";

export const getCandyMachineState = async (
  connection: Connection,
  wallet: WalletContextState,
  candyMachineId: PublicKey,
): Promise<CandyMachineAccount> => {
  const program = await loadCandyProgramV2(wallet, connection);

  const state: any = await program.account.candyMachine.fetch(candyMachineId);
  const itemsAvailable = state.data.itemsAvailable.toNumber();
  const itemsRedeemed = state.itemsRedeemed.toNumber();
  const itemsRemaining = itemsAvailable - itemsRedeemed;

  return {
    id: candyMachineId,
    program,
    state: {
      authority: state.authority,
      itemsAvailable,
      itemsRedeemed,
      itemsRemaining,
      isSoldOut: itemsRemaining === 0,
      isActive: false,
      isPresale: false,
      isWhitelistOnly: false,
      goLiveDate: state.data.goLiveDate,
      treasury: state.wallet,
      tokenMint: state.tokenMint,
      gatekeeper: state.data.gatekeeper,
      endSettings: state.data.endSettings,
      whitelistMintSettings: state.data.whitelistMintSettings,
      hiddenSettings: state.data.hiddenSettings,
      price: state.data.price,
      retainAuthority: state.data.retainAuthority,
    },
  };
};
