import { Auth } from "@supabase/ui"
import {Navigate, useLocation} from "react-router-dom";
import {AppRoute} from "../routes/AppRoute";
import {ReactNode} from "react";

interface RequireAuthProps {
  children: ReactNode
}

export default function RequireAuth(props: RequireAuthProps) {
  const { user } = Auth.useUser()
  const location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={AppRoute.Login} state={{ from: location }} replace />;
  }

  return (
    <div>
      {props.children}
    </div>
  )
}
