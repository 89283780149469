import { Auth} from '@supabase/ui'
import {supabase} from "../lib/supabase/supabase";
import {Navigate, useLocation} from "react-router-dom";

const Container = (props: any) => {
  const { user } = Auth.useUser();
  const location = useLocation();
  // @ts-ignore
  const from = location.state?.from?.pathname || "/";

  if (user) {
    return <Navigate to={from} replace />;
  }
  return (
    <div className="container-sm shadow-box rounded p-4 mt-4" style={{maxWidth: 500}}>
      {props.children}
    </div>
  )
}

export default function Login() {
  return (
    <div className="container">
      <Auth.UserContextProvider supabaseClient={supabase}>
        <Container supabaseClient={supabase}>
          <Auth supabaseClient={supabase} />
        </Container>
      </Auth.UserContextProvider>
    </div>
  )
}
