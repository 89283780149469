import { toArray } from "../../utils/utils";
import { TraitImage } from "../../types";

export async function getTraitsFromDirectory(rootDirectoryHandle: FileSystemDirectoryHandle): Promise<TraitImage[]> {
  let traitNames: string[] = [];
  for await (const [key, ] of rootDirectoryHandle.entries()) {
    if (!key.startsWith('.')) {
      traitNames.push(key);
    }
  }

  let traitImages: TraitImage[] = [];
  await Promise.all(
    traitNames.map(async traitName => {
      let currentTraitDirectory = await rootDirectoryHandle.getDirectoryHandle(traitName);
      let currentTraitDirectoryEntries = (await toArray(currentTraitDirectory.entries()))
        .filter(([fileName,]) => !fileName.startsWith('.'));

      await Promise.all(
        currentTraitDirectoryEntries
          .map(async ([fileName,]) => {
            let imageFileHandle = await currentTraitDirectory.getFileHandle(fileName);
            let imageFile = await imageFileHandle.getFile();
            let imageArrayBuffer = await imageFile.arrayBuffer();
            let imageBlob = new Blob([imageArrayBuffer], {type: 'image/png'});
            let imageUrl = URL.createObjectURL(imageBlob);

            traitImages.push({
              Trait: traitName,
              Value: fileName.split('.')[0],
              DataUrl: imageUrl,
              Rarity: Number((1 / currentTraitDirectoryEntries.length).toFixed(2))
            });
        })
      )
    })
  );

  return traitImages;
}
