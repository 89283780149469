import {loadCandyProgramV2} from "./utils";
import {Connection} from "@solana/web3.js";
import {WalletContextState} from "@solana/wallet-adapter-react";
import {awaitTransactionSignatureConfirmation} from "./mint";

export async function withdraw(connection: Connection, wallet: WalletContextState, candyMachineAddress: string) {
  const program = await loadCandyProgramV2(wallet, connection);
  const txId = await program.rpc.withdrawFunds({
    accounts: {
      candyMachine: candyMachineAddress,
      authority: wallet.publicKey!,
    },
  });

  await awaitTransactionSignatureConfirmation(
    txId,
    parseInt(process.env.REACT_APP_SOLANA_TX_RECENT_TIMEOUT!),
    connection,
    'recent',
  );
}
