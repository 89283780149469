import {AlertTemplateProps, AlertType, types as AlertTypes} from "react-alert";
import React from "react";

const alertTypesToClass = (alertType: AlertType) => {
  switch (alertType) {
    case "success": return 'alert-success';
    case "error": return 'alert-danger';
    case "info": return 'alert-info';
  }
}

const AlertTemplate = ({ style, options, message, close }: AlertTemplateProps) => {

  return (
    <div className={`${alertTypesToClass(options.type ?? AlertTypes.INFO)} container rounded border border-2 border-dark shadow-box`} style={{
      maxWidth: 500,
      ...style
    }}>
      <div className="row px-2 align-items-md-center">
        <div className="col text-end align-items-baseline">
          <i className="fs-2 bi-x text-muted" style={{cursor: "pointer"}} onClick={close}/>
        </div>
      </div>
      <div className="row px-3 py-2">
        <div className="col text-dark">
          <div>
            {message}
          </div>
        </div>
      </div>
    </div>
  )
};

export default AlertTemplate;
