import Arweave from 'arweave';
import { ArweaveTransaction } from '../types/ArweaveTransaction';
import { ArweaveTransactionStatus } from '../types/ArweaveTransactionStatus';

const viewBlockBaseUrl = 'https://viewblock.io/arweave/tx';
export const arweaveBaseUrl = 'https://arweave.net';

export function userHasArweaveWallet() {
  return !!window.arweaveWallet;
}

async function uploadToArweave(buffer: string | ArrayBuffer | Uint8Array, contentType: string): Promise<ArweaveTransaction> {
  const arweave = Arweave.init({ host: 'arweave.net', protocol: 'https', port: 443 });

  const transaction = await arweave.createTransaction({
    data: buffer
  });

  transaction.addTag('Content-Type', contentType);
  transaction.addTag('App-Name', process.env.REACT_APP_NAME ?? '');
  transaction.addTag('App-Version', process.env.REACT_APP_VERSION ?? '');

  await arweave.transactions.sign(transaction);

  let uploader = await arweave.transactions.getUploader(transaction);
  while (!uploader.isComplete) {
    await uploader.uploadChunk();
  }

  return {
    Id: transaction.id,
    ViewBlockLink: `${viewBlockBaseUrl}/${transaction.id}`,
    ArweaveLink: `${arweaveBaseUrl}/${transaction.id}`,
    Status: ArweaveTransactionStatus.New,
    Confirmations: 0,
    RefreshDate: new Date()
  };
}

async function updateArweaveTransaction(id: string): Promise<ArweaveTransaction | undefined> {
    const arweave = Arweave.init({ host: 'arweave.net', protocol: 'https', port: 443 });
    let status = await arweave.transactions.getStatus(id);

    return {
      Id: id,
      ViewBlockLink: `${viewBlockBaseUrl}/${id}`,
      ArweaveLink: `${arweaveBaseUrl}/${id}`,
      Status: ArweaveTransactionStatus.Complete,
      Confirmations: status.confirmed?.number_of_confirmations ?? 0,
      RefreshDate: new Date()
    };
}

export {
  uploadToArweave,
  updateArweaveTransaction
}
