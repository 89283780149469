import weighted from 'weighted';
import _ from 'lodash';
import { Metadata, TraitsConfiguration } from '../../types';
import {Collection} from "../supabase/models";

function basename(path: string) {
  return path.split('/').reverse()[0];
}

function getMetadata(
  name: string = '',
  symbol: string = '',
  index: number = 0,
  creators: any,
  description: string = '',
  seller_fee_basis_points: number = 500,
  attrs: any,
  collection: any,
): Metadata {

  const attributes = [];
  for (const prop in attrs) {
    attributes.push({
      trait_type: prop,
      value: basename(attrs[prop]),
    });
  }
  return {
    name: `${name}${index + 1}`,
    symbol,
    image: `${index + 1}.png`,
    properties: {
      files: [
        {
          uri: `${index + 1}.png`,
          type: 'image/png',
        },
      ],
      category: 'image',
      creators,
    },
    description,
    seller_fee_basis_points,
    attributes,
    collection,
  };
};

function generateRandomSet(breakdown: any) {
  const tmp: any = {};
  Object.keys(breakdown).forEach(attr => {
    const randomSelection = weighted.select(breakdown[attr]);
    tmp[attr] = randomSelection;
  });

  return tmp;
};

async function createMetadataFiles(numberOfImages: number, config: TraitsConfiguration, sellerFeeBasisPoints: number) : Promise<[boolean, string, Metadata[]]> {

  let numberOfFilesCreated: number = 0;
  const randomizedSets = [];
  let metadatas: Metadata[] = [];

  const {
      breakdown,
      name,
      symbol,
      creators,
      description,
      seller_fee_basis_points,
      collection,
  } = config;

  let success = true;
  let error = '';

  /*
  The loop below generates **unique** assets from randomized sets
  of traits. It's possible that it is unable to produce the desired
  # of assets if there are not enough traits. This is the max
  # of iterations to perform to get to the desired # of assets.
   */
  const maxIterations = numberOfImages * 2;
  let i = 0;
  while (numberOfFilesCreated < numberOfImages && i < maxIterations) {
      const randomizedSet = generateRandomSet(breakdown);

      if (!_.some(randomizedSets, randomizedSet)) {
        const metadata = getMetadata(
          name,
          symbol,
          numberOfFilesCreated,
          creators,
          description,
          seller_fee_basis_points,
          randomizedSet,
          collection,
      );

        randomizedSets.push(randomizedSet);
        metadatas.push(metadata);

        numberOfFilesCreated += 1;
      }

      i++;
  }

  if (i === maxIterations) {
    success = false
    error = `Could not produce enough unique assets from the specified traits. Generated ${numberOfFilesCreated} out of the ${numberOfImages} requested. Reduce the supply or add more traits to resolve this issue.`;
  }

  return [success, error, metadatas];
}

export default async function generateArtJson(
  config: TraitsConfiguration, collection: Collection) {

  const randomSets = await createMetadataFiles(collection.supply, config, collection.seller_fee_basis_points);

  return randomSets;
}
