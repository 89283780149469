import { Transaction } from "./models";
import {supabase} from "./supabase";
import {definitions} from "./types";

export const addTransactions = async (transactions: Transaction[]) => {
  await supabase
    .from<definitions["transactions"]>("transactions")
    .insert(transactions)
}

export const updateTransaction = async (id: number, status: string, confirmations: number, refreshedAt: string) => {
  await supabase
    .from<definitions["transactions"]>("transactions")
    .update({
      status: status,
      confirmations: confirmations,
      refreshed_at: refreshedAt
    })
    .match({id: id});
}
