import {Metadata} from "../../types";
import {arweaveBaseUrl, uploadToArweave} from "../arweave";
import {AssetWithTransaction, Collection, Transaction} from "../supabase/models";
import {WalletContextState} from "@solana/wallet-adapter-react";
import {getAssetObjectUrl} from "../supabase/asset";

export default async function createMetadata(
  collection: Collection, asset: AssetWithTransaction, wallet: WalletContextState): Promise<Transaction> {
  const assetObjectUrl = await getAssetObjectUrl(asset);
  let blob = (await (await fetch(assetObjectUrl)).blob());
  const buffer = await blob?.arrayBuffer();
  const imageArweaveTransaction = await uploadToArweave(buffer, 'image/png');

  const metadata: Metadata = {
    name: `${collection.name} #${asset.name}`,
    symbol: collection.symbol,
    image: `${arweaveBaseUrl}/${imageArweaveTransaction.Id}`,
    properties: {
      files: [{
        uri: `${arweaveBaseUrl}/${imageArweaveTransaction.Id}`,
        type: 'image/png'
      }],
      category: 'image',
      creators: [
        {
          address: wallet.publicKey!.toBase58(),
          share: 100
        }
      ]
    },
    description: collection.description ?? '',
    seller_fee_basis_points: collection.seller_fee_basis_points,
    attributes: asset.metadata.attributes,
    collection: {
      family: collection.family,
      name: collection.name
    }
  }

  const metadataArweaveTransaction = await uploadToArweave(JSON.stringify(metadata), 'application/json');

  return {
    asset_id: asset.id,
    transaction_id: metadataArweaveTransaction.Id,
    status: metadataArweaveTransaction.Status,
    confirmations: metadataArweaveTransaction.Confirmations,
    refreshed_at: metadataArweaveTransaction.RefreshDate.toISOString()
  };
}
