import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {StepHeader, TraitsDragNDrop} from "../components";
import AssetCard from "../components/AssetCard";
import Loader from "../components/Loader";
import {generateArtConguration, generateArtJson} from "../lib/generativeArt";
import {getAssets, getCollection, getTraits} from "../lib/supabase";
import {Asset, Collection, Trait} from "../lib/supabase/models";
import {AssetImage, TraitsConfiguration} from "../types";
import {useAlert} from "react-alert";
import { ProgressBar } from "react-bootstrap";
import {generateImages} from "../lib/generativeArt/generateArtImage";
import {clearAssets} from "../lib/supabase/asset";

const ASSETS_TO_DISPLAY = 100;

export default function GenerateImages() {

  const [loaderText, setLoaderText] = useState("Loading");

  const params = useParams();
  const collectionId = parseInt(params.collectionId!);

  const [collection, setCollection] = useState<Collection | undefined>(undefined);
  const [traitsConfig, setTraitsConfig] = useState<TraitsConfiguration | undefined>();
  const [displayedAssets, setDisplayedAssets] = useState<Asset[] | AssetImage[]>([]);
  const [imageGenerationProgress, setImageGenerationProgress] = useState(0);
  const [imageGenerationProgressLimit, setImageGenerationProgressLimit] = useState(0);
  const [traits, setTraits] = useState<Trait[]>([]);

  const refresh = useCallback(async function (reloadTraitConfig: boolean = true) {
    const loadedCollection = await getCollection(collectionId);
    const loadedTraits = await getTraits(collectionId);
    const storedAssets = await getAssets(collectionId, ASSETS_TO_DISPLAY);

    setCollection(loadedCollection);
    setDisplayedAssets(storedAssets.sort((a, b) => parseInt(a.name) - parseInt(b.name)));

    if (loadedCollection && reloadTraitConfig) {
      setTraits(loadedTraits);
      setTraitsConfig(await generateArtConguration(loadedTraits));
    }

    setLoaderText('');
  }, [collectionId])

  useEffect(() => {
    refresh();
  }, [refresh, collectionId])

  const alert = useAlert();

  const generateArt = useCallback(async (sampleSize?: number) => {
    if (!collection || !traitsConfig) return;

    let [success, error, metadatas] = await generateArtJson(traitsConfig, collection);

    if (!success) {
      alert.error(error);
      return;
    }

    const progressLimit = sampleSize ?? metadatas.length;
    const message = sampleSize ?
      '✨ Generating sample... ✨'
      : '✨ Generating all images... ✨'

    setLoaderText(message);
    setImageGenerationProgress(0);
    setImageGenerationProgressLimit(progressLimit)
    let imagesGenerated = 0;

    await clearAssets(collectionId);

    if (sampleSize) {
      metadatas = metadatas.slice(0, sampleSize);
    }

    await generateImages(collectionId, metadatas, traits, traitsConfig.order, traitsConfig.width, traitsConfig.height, () => {
      imagesGenerated++;
      if (imagesGenerated % 10 === 0) {
        setImageGenerationProgress(imagesGenerated);
        setLoaderText(`${message} (${imagesGenerated}/${progressLimit} complete)`);
      }
    });

    await refresh(false);
  }, [traitsConfig, collection, collectionId]);

  return (
    <div className="container">

      <StepHeader title="Generate Images"/>

      {traitsConfig &&
          <div>
              <div className="row mt-4 justify-content-center mb-4">
                  <div className="col-4 text-center shadow-box p-3 bg-secondary">
                      <h3>Layering</h3>
                      <TraitsDragNDrop items={traitsConfig.order} onItemReorder={(newOrder) => {
                        setTraitsConfig({
                          ...traitsConfig,
                          order: newOrder
                        });
                      }}/>
                      <button type="button" className="btn btn-primary mt-2 me-3" onClick={() => generateArt(100)} disabled={!!loaderText}>
                        Sample
                      </button>
                      <button type="button" className="btn btn-primary mt-2" onClick={() => generateArt()} disabled={!!loaderText}>
                        Generate All
                      </button>
                  </div>
              </div>
          </div>
      }

      <div className="mt-5">
        {loaderText ?
          <div className="row justify-content-center">
            <div className="col-md-9">
              {imageGenerationProgress > 0 ?
                <div className="text-center">
                  <p>{loaderText}</p>
                  <ProgressBar animated style={{height: "5ex"}}
                               min={0} max={imageGenerationProgressLimit}
                               now={imageGenerationProgress}/>
                </div>
                :
                <Loader className="text-center">{loaderText}</Loader>
              }
            </div>
          </div>
          :
          <div>
            <div className="row">
              <div className="col">
                <div className="asset-grid">
                  {displayedAssets.map((asset, i) =>
                    <AssetCard key={i} asset={asset}></AssetCard>
                  )}
                </div>
              </div>
            </div>
            {collection
              && displayedAssets.length > 0
              && displayedAssets.length < collection.supply
              &&
              <div className="row my-5 justify-content-center">
                <div className="col-md-6">
                  <p>👋 Hello perfectionist. Looking to review every single image in your collection? Sorry that's
                      not an option right now.</p>
                  <p className="text-start">
                    Only the first 100 out of the {collection.supply} total images are shown. But rest assured that
                    all the images have been generated.
                  </p>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}
