/* @ts-ignore */
import { createCanvas} from 'canvas';
import log from 'loglevel';
import { Metadata} from '../../types';
import {Trait} from "../supabase/models";
import {supabase} from "../supabase/supabase";
import {createBucket, getAssetBucketId} from "../supabase/utils";
import _ from "lodash";

async function drawImage(url: string, ctx: CanvasRenderingContext2D) {
  let img = new Image();
  img.src = url;
  img.crossOrigin="anonymous";
  await new Promise(r => img.onload=r);
  ctx.drawImage(img, 0, 0);
}

function makeCreateImageWithCanvas(
  traitImages: Trait[], orderedTraits: any, width: any, height: any, images: {id: number, dataUrl?: string}[]) {
  return function makeCreateImage(canvas: HTMLCanvasElement, context: any) {
    return async function createImage(metadata: any) {
      for(let currentTrait of orderedTraits) {
        let metadataAttribute = metadata.attributes.find((a: any) => a.trait_type === currentTrait);
        let attributeTraitImage = traitImages.find(t => t.value === metadataAttribute.value);

        await drawImage(attributeTraitImage!.object_url!, context);
      }

      images.push({
        id: parseInt(metadata.name, 10),
        dataUrl: canvas.toDataURL()
      });
    };
  };
}

const CONCURRENT_WORKERS = 50;

const worker = (work: any, next_: any, callback?: (next: any) => void) => async () => {
  let next;
  while ((next = next_())) {
    await work(next);
    if (callback) callback(next);
  }
};

function getOrderedTraits(orderedTraitNames: string[], metadata: Metadata, traits: Trait[]) {
  let orderedTraits: Trait[] = [];
  for (const orderedTrait of orderedTraitNames) {
    const attribute = metadata.attributes.find(a => a.trait_type === orderedTrait);
    const trait = traits.find(t =>
      t.type === attribute!.trait_type
      && t.value === attribute!.value
    );
    orderedTraits.push(trait!)
  }
  return orderedTraits;
}

export async function generateImages(collectionId: number, metadatas: Metadata[], traits: Trait[], orderedTraitNames: string[],
                                     width: number, height: number, callback?: (metadata: Metadata) => void) {
  interface RequestData {
    collectionId: number,
    metadata: Metadata
    orderedTraitUrls: string[],
    width: number,
    height: number
  }

  const bucketId = getAssetBucketId(collectionId);
  await createBucket(bucketId);

  const chunkSize = 20;
  const metadataChunks = _.chunk(metadatas, chunkSize);

  for (const chunk of metadataChunks) {
    await Promise.all(
      chunk.map(async metadata => {
        let orderedTraits = getOrderedTraits(orderedTraitNames, metadata, traits);

        const requestData: RequestData = {
          collectionId,
          metadata,
          orderedTraitUrls: orderedTraits.map(t => t.object_url!),
          width,
          height
        };

        const response = await fetch(process.env.REACT_APP_GENERATE_IMAGE_FUNCTION_URL!, {
            body: JSON.stringify(requestData),
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${supabase.auth.session()?.access_token}`
            }
          }
        );

        if (!response.ok) {
          console.log(response.json());
        } else {
          if (callback) callback(metadata);
        }
      })
    );
  }
}

export default async function generateArtImage(
  traitImages: Trait[],
  config: any,
  metadatas: Metadata[],
  numberOfImages: number,
  onImageGenerated?: (info: any) => void
) {

  let metadatasCopy = Array.from(metadatas);

  const start = Date.now();
  const { order, width, height } = config;
  let images: {id: number, dataUrl?: string}[] = [];

  const makeCreateImage = makeCreateImageWithCanvas(traitImages, order, width, height, images);

  const workers = [];
  const workerNb = Math.min(CONCURRENT_WORKERS, numberOfImages);
  console.info(
    `Instanciating ${workerNb} workers to generate ${numberOfImages} images.`,
  );
  for (let i = 0; i < workerNb; i++) {
    /* @ts-ignore */
    const canvas: HTMLCanvasElement = createCanvas(width, height);
    const context = canvas.getContext('2d');

    const work = makeCreateImage(canvas, context);
    const w = worker(work, metadatasCopy.pop.bind(metadatasCopy), onImageGenerated);
    workers.push(w());
  }

  await Promise.all(workers);
  const end = Date.now();
  const duration = end - start;
  log.info(
    `Generated ${numberOfImages} images in`,
    `${duration / 1000}s.`,
  );

  return images;
}
