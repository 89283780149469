import {AlertContainer, AlertInstance} from "react-alert";
import {ReactNode} from "react";

interface Props {
  message: ReactNode
  cancelText: string
  confirmText: string
  onConfirm: () => void
}

export default function (alert: AlertContainer, props: Props) {
  let alerts: AlertInstance[] = [];
  alerts.push(alert.info(
    <div>
      <div>{props.message}</div>
      <div className="row">
        <div className="col text-end">
          <button className="btn btn-light me-2" onClick={() => {
            alert.remove(alerts[0]);
          }}>
            {props.cancelText}
          </button>
        </div>
        <div className="col">
          <button className="btn btn-danger" onClick={() => {
            props.onConfirm();
            alert.remove(alerts[0]);
          }}>
            {props.confirmText}
          </button>
        </div>
      </div>
    </div>
    , {timeout: 0}));
}
