import {Connection, PublicKey} from "@solana/web3.js";
import {WalletContextState} from "@solana/wallet-adapter-react";
import {loadCandyProgramV2, toCandyMachineData, uuidFromConfigPublicKey} from "./utils";
import {Collection} from "../supabase/models";

export const updateCandyMachine = async function (
  connection: Connection,
  wallet: WalletContextState,
  collection: Collection,
  candyAddress: PublicKey
) {

  const program = await loadCandyProgramV2(wallet, connection);

  let candyMachineData = toCandyMachineData(collection, wallet);
  candyMachineData.uuid = uuidFromConfigPublicKey(candyAddress);

  // @ts-ignore
  const tx = await program.rpc.updateCandyMachine(candyMachineData, {
    accounts: {
      candyMachine: candyAddress,
      authority: wallet.publicKey,
      wallet: wallet.publicKey,
    },
    remainingAccounts: undefined,
  });

  return tx;
};
