import {Asset} from "../lib/supabase/models";
import {AssetImage, Metadata} from "../types";
import {useCallback, useEffect, useState} from "react";

type Props = {
    asset: Asset | AssetImage
}

type Attributes = {
  name: string,
  src: string,
  metadata: Metadata
}

const isAsset = (asset: Asset | AssetImage): asset is Asset => {
  return 'object_url' in asset;
}

const isAssetImage = (asset: Asset | AssetImage): asset is AssetImage => {
  return 'DataUrl' in asset;
}

function AssetCard(props: Props) {
  const [attributes, setAttributes] = useState<Attributes>();
  const getAttributesFromAsset = useCallback((props: Props) => {
    if (isAsset(props.asset)) {
      return {
        name: props.asset.name,
        src: props.asset.object_url ?? '',
        metadata: props.asset.metadata
      }
    } else if (isAssetImage(props.asset)) {
      return {
        name: props.asset.Id?.toString(),
        src: props.asset.DataUrl ?? '',
        metadata: props.asset.Metadata
      }
    }
  }, [props.asset]);

  useEffect(() => {
    setAttributes(getAttributesFromAsset(props)!);
  }, [props.asset])

  if (!attributes) return (
    <p>Loading</p>
  )

  return (
    <div className="card text-center">
      <img className="card-img-top" src={attributes.src} alt={`NFT ID ${attributes.name}`}></img>
      <div className="card-body">
        <h2 className="card-title">#{attributes.name}</h2>
        <table className="table table-striped table-sm fs-6 text-start shadow-box rounded">
          <thead className="table-active">
            <tr>
                <th>Trait</th>
                <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {attributes.metadata.attributes.map(a =>
              <tr key={`${a.trait_type}${a.value}`}>
                  <td key={`${a.trait_type}${a.value}_1`}>{a.trait_type}</td>
                  <td key={`${a.trait_type}${a.value}_2`}>{a.value}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AssetCard
