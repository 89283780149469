import {TraitImage} from "../../types";
import {definitions} from "./types";
import {Trait} from "./models";
import {supabase} from "./supabase";
import {createBucket} from "./utils";

function getTraitBucketId(collectionId: number) {
  const bucketId = `collection-${collectionId}-traits`;
  return bucketId;
}

export const addTraits = async (collectionId: number, traits: TraitImage[]) => {
  const bucketId = getTraitBucketId(collectionId);

  await createBucket(bucketId);

  await supabase
    .from<definitions["traits"]>("traits")
    .delete()
    .eq('collection_id', collectionId)

  for (const trait of traits) {
    let blob = await fetch(trait.DataUrl).then(o => o.blob());
    const {data} = await supabase
      .storage
      .from(bucketId)
      .upload(`${trait.Trait}-${trait.Value}.png`, blob, {
        contentType: 'image/png'
      });

    await supabase
      .from<definitions["traits"]>("traits")
      .insert({
        collection_id: collectionId,
        object_name: data!.Key,
        type: trait.Trait,
        value: trait.Value,
        rarity: trait.Rarity
      });
  }
}

export const getTraits = async (collectionId: number, loadObjects: boolean = true): Promise<Trait[]> => {
  const bucketId = getTraitBucketId(collectionId);

  const {data: traits} = await supabase
    .from<definitions["traits"]>("traits")
    .select('*')
    .eq('collection_id', collectionId)
    .order('type')
    .order('value');

  if (loadObjects && traits && traits.length > 0) {
    await Promise.all(traits.map(async (t: Trait) => {
      const objectPath = t.object_name.replace(`${bucketId}/`, '');
      const {data} = await supabase
        .storage
        .from(bucketId)
        .createSignedUrl(objectPath, 3600);

      t.object_url = data!.signedURL;
    }));
  }

  return traits || [];
}

export const updateTraitRarity = async (id: number, rarity: number) => {
  await supabase
    .from<definitions["traits"]>("traits")
    .update({rarity: rarity})
    .match({id: id});
}
