type Props = {
    className?: string
    children?: React.ReactNode
}

function Loader(props: Props) {
    return (
        <div className={props.className}>
            <div className="spinner-border text-primary" role="status"></div>
            <div>
                {props?.children}
            </div>
        </div>
    )
}

export default Loader

