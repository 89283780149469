import _ from 'lodash';
import {TraitsConfiguration} from '../../types/TraitsConfiguration';
import {Trait} from "../supabase/models";

export default async function generateArtConguration(
  traitImages: Trait[]) {

  let groupedTraitImages = _.groupBy(traitImages, t => t.type);
  let orderedTraits = Object.keys(groupedTraitImages).sort();

  const imgSizeResult = await getImageSize(traitImages[0].object_url!);
  if (imgSizeResult instanceof ErrorEvent) {
    console.error(imgSizeResult);
    return;
  }

  const configs: TraitsConfiguration = {
    name: '',
    symbol: '',
    description: '',
    creators: [],
    collection: {},
    breakdown: {},
    order: orderedTraits,
    width: imgSizeResult.width,
    height: imgSizeResult.height,
    seller_fee_basis_points: 0
  };

  try {
    await Promise.all(
      orderedTraits.map(async traitName => {
        const attributes = groupedTraitImages[traitName];

        const tmp: any = {};
        for (let attr of attributes) {
          tmp[attr.value] = attr.rarity;
        }

        configs.breakdown[traitName] = tmp;
      }),
    );
  } catch (err) {
    console.error('Error created configurations', err);
    throw err;
  }

  return configs;
}

async function getImageSize(src: string): Promise<{width: number, height: number} | ErrorEvent> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", function() {
      resolve({
        width: this.naturalWidth,
        height: this.naturalHeight
      });
    });
    img.addEventListener("error", function(err) {
      reject(err);
    });
    img.src = src;
  });
}
