import {Provider} from "react-redux";
import {store} from "./store/store";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LandingPage} from "./routes/LandingPage";
import {AppRoute} from "./routes/AppRoute";
import Layout from "./routes/Layout";
import UploadTraits from "./routes/UploadTraits";
import GenerateImages from "./routes/GenerateImages";
import UploadToArweave from "./routes/UploadToArweave";
import React from "react";
import './App.scss';
import {ConfigureCandyMachine} from "./routes/ConfigureCandyMachine";
import {Auth} from '@supabase/ui';
import {supabase} from "./lib/supabase/supabase";
import {Login, RequireAuth} from "./components";
import {Collections} from "./routes/Collections";
import InitializeCandyMachine from "./routes/InitializeCandyMachine";
import Mint from "./routes/Mint";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter, SolletWalletAdapter,
  TorusWalletAdapter
} from "@solana/wallet-adapter-wallets";
import {ConnectionProvider, WalletProvider} from "@solana/wallet-adapter-react";
import {WalletModalProvider} from "@solana/wallet-adapter-react-ui";
import './styles/wallet.scss';
import {CollectionDetails} from "./routes/CollectionDetails";
import {useAlert} from "react-alert";

export function App() {

  const wallets = [
    new PhantomWalletAdapter(),
    new SlopeWalletAdapter(),
    new SolflareWalletAdapter(),
    new TorusWalletAdapter(),
    new LedgerWalletAdapter(),
    new SolletWalletAdapter(),
    new SolletExtensionWalletAdapter(),
  ];

  const endpoint = process.env.REACT_APP_SOLANA_ENDPOINT;

  const alert = useAlert();

  return (
    <Provider store={store}>
      <Auth.UserContextProvider supabaseClient={supabase}>
        <ConnectionProvider endpoint={endpoint!} config={{confirmTransactionInitialTimeout: parseInt(process.env.REACT_APP_SOLANA_TX_RECENT_TIMEOUT!)}}>
          <WalletProvider wallets={wallets} autoConnect onError={(e) => alert.error(e.message, {})}>
            <WalletModalProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/">
                      <Route index element={<LandingPage/>} />
                      <Route path={AppRoute.Login} element={<Layout><Login/></Layout>}/>

                      <Route path={AppRoute.Collections} element={<RequireAuth children={<Layout/>} />}>
                        <Route index element={<Collections/>}/>
                        <Route path={AppRoute.CreateCandyMachine} element={<ConfigureCandyMachine/>}/>
                        <Route path=":collectionId">
                          <Route index element={<CollectionDetails />} />
                          <Route path={AppRoute.ConfigureCandyMachine} element={<ConfigureCandyMachine/>}/>
                          <Route path={AppRoute.UploadTraits} element={<UploadTraits/>}/>
                          <Route path={AppRoute.GenerateImages} element={<GenerateImages/>}/>
                          <Route path={AppRoute.UploadToArweave} element={<UploadToArweave/>}/>
                          <Route path={AppRoute.InitializeCandyMachine} element={<InitializeCandyMachine/>}/>
                        </Route>
                      </Route>

                      <Route path={`${AppRoute.Mint}/:candyMachineId`} element={<Mint rpcUrl={endpoint!}/>}/>

                      <Route
                        path="*"
                        element={
                          <p>There's nothing here!</p>
                        }
                      />
                    </Route>
                  </Routes>
                </BrowserRouter>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </Auth.UserContextProvider>
    </Provider>
  );
}
