import {ReactNode, useRef, useState} from "react";
import {Overlay, OverlayTrigger, Tooltip} from "react-bootstrap";

type Props = {
  text: string
  hoverTooltip?: ReactNode
  copyTooltip?: ReactNode
}

const TOOLTIP_TIMEOUT_MS=3000;

export default function (props: Props) {
  const [showCopied, setShowCopied] = useState(false);
  const target = useRef(null);

  function onCopyToClipboard() {
    navigator.clipboard.writeText(props.text).then(
      function() {
        displayCopiedTooltip();
      },
      function (err) {
        console.log(err);
      }
    );
  }

  function displayCopiedTooltip() {
    setShowCopied(!showCopied);
    setTimeout(() => {
      setShowCopied(false);
    }, TOOLTIP_TIMEOUT_MS);
  }

  return (
    <div className="input-group">
      <input className="form-control"
             data-bs-toggle="tooltip" title="Copy candy machine ID"
             type="text" value={props.text} disabled/>

      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{props.hoverTooltip}</Tooltip>}
      >
        <button className="btn btn-light" ref={target} onClick={onCopyToClipboard}>
          <i className="bi-clipboard" />
        </button>
      </OverlayTrigger>

      <Overlay target={target.current} show={showCopied} placement="bottom">
        {(injectedProps) => (
          <Tooltip {...injectedProps}>
            {props.copyTooltip}
          </Tooltip>
        )}
      </Overlay>
    </div>
  )
}
