import { Link} from "react-router-dom";
import Feature from "../components/LandingPage/Feature";
import { AppRoute } from "./AppRoute";
import {Footer, Header} from "../components";
import Banner from "../components/LandingPage/Banner";

export function LandingPage() {
  return (
    <>
      <Banner />

      <div className="container-fluid p-0">

        <Header />

        <div className="container py-5 p-5 my-5 rounded-3">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold">Welcome to Sorbet!</h1>
              <p className="fs-4">
                The simplest way to launch and manage your Solana NFT collection
              </p>
              <Link className="btn btn-primary" to={`${AppRoute.Collections}`}>
                Get started
              </Link>
            </div>
          </div>
        </div>

        <div className="container-fluid landing-page-bg">
          <Feature name='Generate your artwork' img={{
            src: 'generate-artwork.png',
            alt: '',
          }} blob={1}>
            <p>
              There's a little artist in all of us, but technical hiccups can crush creativity. Sorbet handles the details so you can
              focus on making your art shine.
            </p>
          </Feature>

          <Feature name='Upload your collection' reverse img={{
            src: 'upload-images.png',
            alt: ''
          }} blob={2}>
            <p>
              Sorbet uses Arweave to provide sustainable NFTs that are preserved forever. Your art will outlive you.
            </p>
          </Feature>

          <Feature name='Launch your mint site' img={{
            src: 'mint.png',
            alt: ''
          }} blob={3}>
            <p>
              Instantly spin up a Metaplex candy machine and mint site. Sorbet makes updating your candy machine a breeze.
            </p>
          </Feature>
        </div>
      </div>


      <div className="container bg-body p-5">
        <h2>Features and options</h2>
        <div className="row justify-content-center mt-5">
          <div className="col-sm-9 col-md-9">
            <dl className="row feature-list justify-content-center">

              <dt className="col-md-4">
                Image generation
              </dt>
              <dd className="col-md-8">Import your traits and let Sorbet do the rest. Shuffle until you're happy with
                your collection.</dd>

              <dt className="col-md-4">
                Hosted mint site
              </dt>
              <dd className="col-md-8">Launch your candy machine at the click of a button. Your hosted mint site is
                instantly ready.</dd>

              <dt className="col-md-4">Whitelist</dt>
              <dd className="col-md-8">
                Restrict mints to whitelisted user by distributing tokens. Configure your candy machine to burn
                whitelist tokens or allow users to reuse them.
              </dd>

              <dt className="col-md-4">CAPTCHA protection</dt>
              <dd className="col-md-8">Conduct a fair mint to defend against bots with Civic Pass verification.</dd>

              <dt className="col-md-4">Presales</dt>
              <dd className="col-md-8">Allow whitelisted users to mint before the general public.</dd>

              <dt className="col-md-4">Discounts</dt>
              <dd className="col-md-8">Set a discounted price for whitelisted users.</dd>

              <dt className="col-md-4">Upload to Arweave</dt>
              <dd className="col-md-8">Interact directly with Arweave to upload your artwork to the permaweb. Never think about it again.</dd>
            </dl>
          </div>
        </div>

        <h2 className="mt-5">Pricing</h2>
        <p>
          Sorbet is <strong>free</strong>. This will probably change as we grow, but we're dedicated to keeping Sorbet
          low-cost. For now we're focused on building the features you need to create awesome projects.
        </p>

        <h2 className="mt-5">Fees</h2>
        <p>
          Sorbet has no costs. However there are fees associated with creating a candy machine and uploading files to
          Arweave. These fees do not go to us. Some guidance on both candy machine fees and Arweave fees can be found below:
        </p>

        <ul>
          <li>Solana candy machine: <a href="https://feecalc.live/">https://feecalc.live</a></li>
          <li>Arweave upload: <a href="https://arweavefees.com/">https://arweavefees.com</a></li>
        </ul>

        <p>
          Any transactions made (edits or uploads) will have small fees associated with them.  These are not our fees.
          They are incurred by interacting with the blockchain.
        </p>
      </div>

      <Footer/>
    </>
  )
}
