import {Link} from "react-router-dom";

type Props = {
  title: string,
  hrefBackToCollection?: string,
  children?: React.ReactNode
}

export default function StepHeader(props: Props) {
  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-2">
          <Link to="..">
            <button type="button" className="btn mb-2" title="Back to collection details">
              <i className="bi-arrow-left" />
            </button>
          </Link>
        </div>
        <div className="col-8 text-center">
          <h1>{props.title}</h1>
        </div>
      </div>

      <div className="row">
        <div className="col text-end">
          {props?.children}
        </div>
      </div>
    </div>
  );
}
