import {ReactNode} from "react";
import blob1 from '../../styles/blob1.svg';
import blob2 from '../../styles/blob2.svg';
import blob3 from '../../styles/blob3.svg';

type FeatureProps = {
  name: string,
  children: ReactNode,
  img: {
    src: string,
    alt: string
  },
  reverse?: boolean,
  blob: 1 | 2 | 3
}

const Feature = (props: FeatureProps) => {

  const textOrder = props.reverse ? "order-lg-1" : "order-lg-0";
  const imgOrder = props.reverse ? "order-lg-0" : "order-lg-1";
  const blobMap = {
    1: blob1,
    2: blob2,
    3: blob3
  }
  const blob = blobMap[props.blob];

  return (
    <div className="container py-4 py-lg-5 my-lg-5">
      <div className="row my-4 justify-content-around">
        <div className={`col-lg-5 col-xl-4 my-auto px-3 ${textOrder} position-relative`}>
          <img src={blob} alt="Blob" className="bg-blob d-none d-lg-inline"/>
          <h3>{props.name}</h3>
          <div className="p-2">
            {props.children}
          </div>
        </div>
        <div className={`col-lg-6 text-center ${imgOrder}`}>
          <img className="img-fluid rounded shadow" src={props.img.src} alt={props.img.alt}/>
        </div>
      </div>
    </div>
  )
}

export default Feature;
