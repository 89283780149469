import {Auth} from "@supabase/ui";
import {supabase} from "../lib/supabase/supabase";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {Link, useNavigate} from "react-router-dom";
import {AppRoute} from "../routes/AppRoute";

interface HeaderLink {
  href: string,
  text: string
}

const links: HeaderLink[] = [
  { href: AppRoute.Collections, text: 'Collections' }
];

export default function Header() {
  const { user } = Auth.useUser()
  const navigate = useNavigate();

  async function signOut() {
    await supabase.auth.signOut();
    navigate('/');
  }

  return (
    <header className="p-3">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/" target="_self" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none">
            <img height={50} src="/logo.png" />
          </a>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 ms-4">
            {
              links.map(l =>
                <li key={l.text}>
                  <a href={l.href} target="_self" className="nav-link text-dark px-2">{l.text}</a>
                </li>
              )
            }
          </ul>

          <div className="text-end">
            <WalletMultiButton />

            <span className="ms-4">
              {user ?
                <button className="btn btn-sm btn-light" onClick={signOut}>
                  <i className="bi-box-arrow-up-right"/> Sign out
                </button>
                :
                <Link to={AppRoute.Login}>
                  <button type="button" className="btn btn-sm btn-success me-2">Login</button>
                </Link>
              }
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}
