import { Outlet } from 'react-router-dom';
import {Footer, Header} from "../components";
import Banner from "../components/LandingPage/Banner";

export default function Layout(props: any) {

  return (
    <div>
      <Header />

      <Outlet />
      {props.children}

      <Footer />
    </div>
  );
}
