import _ from "lodash";
import {useCallback, useEffect, useState} from "react";
import Loader from "../components/Loader";
import TraitImageCard from "../components/TraitImageCard";
import {getTraitsFromDirectory} from "../lib/generativeArt";
import {useParams} from "react-router-dom";
import {StepHeader} from "../components";
import {addTraits, getTraits, updateTraitRarity} from "../lib/supabase";
import {Trait} from "../lib/supabase/models";
import checkTraitWeights from "../lib/generativeArt/checkTraitWeights";

export default function UploadTraits() {
  const [isLoading, setIsLoading] = useState(false);
  const [traits, setTraits] = useState<Trait[]>([]);
  const params = useParams();
  const collectionId = parseInt(params.collectionId!);

  function updateTrait(predicate: (t: Trait) => boolean, trait: Trait) {
    const index = traits.findIndex(predicate);
    if (index === -1) return;
    else
    setTraits([
      ...traits.slice(0,index),
      Object.assign({}, traits[index], trait),
      ...traits.slice(index + 1)
    ]);
  }

  const rarityAddsUp = useCallback(function (traits: Trait[]) {
    return checkTraitWeights(traits);
  }, [traits])

  const refresh = useCallback(async function () {
    setTraits(await getTraits(collectionId));
  }, [collectionId])

  useEffect(() => {
   refresh();
  }, [refresh, collectionId])

  async function openFileAccess() {
    try {
      let rootDirectoryHandle = await showDirectoryPicker();
      let images = await getTraitsFromDirectory(rootDirectoryHandle);

      setIsLoading(true);
      await addTraits(collectionId, images);
      refresh();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="container mb-5">

      <StepHeader title="Add Traits"></StepHeader>

      <div className="row justify-content-center mt-4">
        <div className="col-md-9">
          <p>Import a directory of trait files from your device.</p>
          <ul>
            <li>The directory should only contain PNGs</li>
            <li>All PNGs should be the same size (1200x1200 for example)</li>
            <li>The directory should follow this structure
              <pre className="p-2 rounded">
                <code>
                  {
                    `traits
├── Background
├── Eyes
├── Face
└── Mouth`
                  }
                </code>
            </pre>
            </li>
          </ul>
          <p>
            <a href="/example-traits.zip" target="_self">Download an example</a>.
            Unzip this file and upload the directory to see how it works.
          </p>
        </div>
      </div>

      <div className="p-4 my-3">
        <div className="row mt-4 mb-5">
          <div className="col text-center">
            <button type="button" className="btn btn-primary" onClick={openFileAccess}>Upload files</button>
          </div>
        </div>

        {isLoading
          ? <Loader className="text-center">✨ Uploading traits... ✨</Loader>
          :
          <>
            {Object.entries(
              _.chain(traits)
                .groupBy(t => t.type)
                .value()
            ).map(([trait, theseTraits]) =>
              <fieldset key={trait} className="mb-5">
                <legend>
                  Trait: {trait} (
                    {(theseTraits
                      .map(t => t.rarity)
                      .reduce((prev, cur) => prev + cur) * 100)
                      .toFixed(0)
                    }
                  %) {rarityAddsUp(theseTraits) ? '✅' : '❌'}
                </legend>
                {!rarityAddsUp(theseTraits) &&
                    <p className="alert-danger p-3 rounded">
                        <i className="bi-exclamation-circle"/> Rarity does not add up to 100. Please adjust.
                    </p>
                }
                <div className="trait-grid">
                  {theseTraits.map(trait =>
                    <TraitImageCard
                      key={`${trait.type}-${trait.value}`} trait={trait}
                      onRarityChange={async (e) => {
                        const fractionalValue = e.rarity / 100;
                        await updateTraitRarity(e.trait.id, fractionalValue);
                        console.log(`Updated rarity to ${fractionalValue}`);
                        updateTrait(t =>
                          t.type === e.trait.type
                          && t.value === e.trait.value
                          , {
                            ...e.trait,
                            rarity: fractionalValue
                          });
                      }}
                    />
                  )}
                </div>
              </fieldset>
            )}
          </>
        }
      </div>

    </div>
  )
}
