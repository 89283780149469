import {StepHeader} from "../components";
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {AssetWithTransaction, Collection} from "../lib/supabase/models";
import {getAssetsWithTransactions, getCollection} from "../lib/supabase";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {createSorbetCandyMachine} from "../lib/candyMachine";

export default function InitializeCandyMachine() {
  const params = useParams();
  const collectionId = parseInt(params.collectionId!);

  const [assets, setAssets] = useState<AssetWithTransaction[]>([]);
  const [collection, setCollection] = useState<Collection>();

  const refresh = useCallback(async function () {
    const loadedCollection = await getCollection(collectionId);
    const loadedAssets = await getAssetsWithTransactions(collectionId);

    setCollection(loadedCollection);
    setAssets(loadedAssets);
  }, [collectionId])

  useEffect(() => {
    refresh();
  }, [refresh])

  const wallet = useWallet();
  const {connection} = useConnection();

  async function initialize() {
    if (wallet && collection) {
      await createSorbetCandyMachine(connection, wallet, collection);
    } else {
      alert('Connect your wallet to continue');
    }
  }

  return (
    <div className="container">

      <StepHeader title="Initialize Candy Machine">
      </StepHeader>

      <div className="row mt-4 mb-5">
        <div className="col text-center">
          <button type="button" className="btn btn-primary" onClick={initialize}>Initialize</button>
        </div>
      </div>

    </div>
  )
}
