import './Banner.module.scss';
import 'bootstrap/js/dist/alert';

export default function Banner() {
  return (
    <div id="banner" className="alert alert-primary alert-dismissible show position-sticky" role="alert">
      <p className="m-0 text-center text-body">
        Welcome to Sorbet! If you're new here, check out our <a href="https://blog.sorbet.studio/why-sorbet/">introductory blog post</a>.
      </p>
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
    </div>
  );
}
