import {AssetImage} from "../../types";
import {supabase} from "./supabase";
import {createBucket, getAssetBucketId} from "./utils";
import {Asset, AssetWithTransaction} from "./models";
import _ from "lodash";

export const addAssets = async (collectionId: number, assets: AssetImage[], onAdd?: (asset: Asset) => void) => {
  const bucketId = getAssetBucketId(collectionId);

  await createBucket(bucketId);

  await supabase
    .from<Asset>("assets")
    .delete()
    .eq('collection_id', collectionId)

  const assetsChunk = _.chunk(assets, 20);

  for (const chunk of assetsChunk) {
    await Promise.all(
      chunk.map(async asset => {
        let blob = await fetch(asset.DataUrl!).then(o => o.blob());
        const {data} = await supabase
          .storage
          .from(bucketId)
          .upload(`${asset.Id}.png`, blob, {
            contentType: 'image/png'
          });

        const {data: newAsset} = await supabase
          .from<Asset>("assets")
          .insert({
            collection_id: collectionId,
            object_name: data!.Key,
            name: `${asset.Id}`,
            metadata: asset.Metadata
          });

        if (onAdd) onAdd(newAsset![0]);
      })
    );
  }
}

export const clearAssets = async(collectionId: number) => {
  await supabase
    .from<Asset>('assets')
    .delete()
    .eq('collection_id', collectionId);
}

export const getAssets = async (collectionId: number, limit: number): Promise<Asset[]> => {
  const bucketId = getAssetBucketId(collectionId);

  const {data: assets} = await supabase
    .from<Asset>("assets")
    .select('*')
    .eq('collection_id', collectionId)
    .order("id")
    .limit(limit);

  if (assets && assets.length > 0) {
    await Promise.all(assets.map(async (a: Asset) => {
      const objectPath = a.object_name.replace(`${bucketId}/`, '');
      const {data} = await supabase
        .storage
        .from(bucketId)
        .createSignedUrl(objectPath, 3600);

      a.object_url = data!.signedURL;
    }));
  }

  return assets || [];
}

export const getAssetsWithTransactions = async (collectionId: number, loadObjects: boolean = true): Promise<AssetWithTransaction[]> => {
  const bucketId = getAssetBucketId(collectionId);

  const {data: assetsWithTransaction} = await supabase
    .from<AssetWithTransaction>("assets")
    .select(`
      id,
      collection_id,
      name,
      object_name,
      metadata,
      transactions (
        id,
        transaction_id,
        status,
        confirmations,
        refreshed_at
      )
    `)
    .eq('collection_id', collectionId)
    .order('id')
    .order('id', {foreignTable: 'transactions', ascending: false});

  if (loadObjects && assetsWithTransaction && assetsWithTransaction.length > 0) {
    await Promise.all(assetsWithTransaction.map(async (a: AssetWithTransaction) => {
      const objectPath = a.object_name.replace(`${bucketId}/`, '');
      const {data} = await supabase
        .storage
        .from(bucketId)
        .createSignedUrl(objectPath, 3600);

      a.object_url = data!.signedURL;
    }));
  }

  return assetsWithTransaction || [];
}

export const getAssetObjectUrl = async (asset: Asset | AssetWithTransaction) => {
  const bucketId = getAssetBucketId(asset.collection_id);

  const objectPath = asset.object_name.replace(`${bucketId}/`, '');
  const {data} = await supabase
    .storage
    .from(bucketId)
    .createSignedUrl(objectPath, 3600);

  return data!.signedURL;
}
