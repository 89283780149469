import {Connection, Keypair, PublicKey, SystemProgram} from "@solana/web3.js";
import {CANDY_MACHINE_PROGRAM_V2_ID, CONFIG_ARRAY_START_V2, CONFIG_LINE_SIZE_V2} from "./constants";
import * as anchor from '@project-serum/anchor';
import {CandyMachineData} from "./types";
import {WalletContextState} from "@solana/wallet-adapter-react";
import {AssetWithTransaction} from "../supabase/models";
import {arweaveBaseUrl} from "../arweave";
import {loadCandyProgramV2, uuidFromConfigPublicKey} from "./utils";
import {awaitTransactionSignatureConfirmation} from "./mint";

export type ConfigLineUploadProgress = {
  index: number,
  txId: string,
  total: number
}
export type ConfigLineUploadHandler = (resp: ConfigLineUploadProgress) => void;

export async function writeIndices(connection: Connection, assetsWithTransactions: AssetWithTransaction[],
                            candyMachine: Keypair, wallet: WalletContextState, assetUploaded?: ConfigLineUploadHandler) {
  const keys = Object.keys(assetsWithTransactions).map(k => parseInt(k));
  const poolArray = [];
  const allIndicesInSlice = Array.from(Array(assetsWithTransactions.length).keys());
  let offset = 0;
  while (offset < allIndicesInSlice.length) {
    let length = 0;
    let lineSize = 0;
    let configLines = allIndicesInSlice.slice(offset, offset + 16);
    while (
      length < 850 &&
      lineSize < 16 &&
      configLines[lineSize] !== undefined
      ) {
      const data = assetsWithTransactions[keys[configLines[lineSize]]];
      const metadataUrl = `${arweaveBaseUrl}/${data.transactions[0].transaction_id}`;
      length += metadataUrl.length + data.name.length;
      if (length < 850) lineSize++;
    }
    configLines = allIndicesInSlice.slice(offset, offset + lineSize);
    offset += lineSize;
    const index = keys[configLines[0]];
    poolArray.push({ index, configLines });
  }

  const anchorProgram = await loadCandyProgramV2(wallet, connection);

  await Promise.all(
    poolArray.map(async ({ index, configLines }) => {
      const transaction = await anchorProgram.transaction.addConfigLines(
        // @ts-ignore
        index,
        configLines.map(i => ({
          uri: `${arweaveBaseUrl}/${assetsWithTransactions[keys[i]].transactions[0].transaction_id}`,
          name: assetsWithTransactions[keys[i]].name,
        })),
        {
          accounts: {
            candyMachine: candyMachine.publicKey,
            authority: wallet.publicKey!,
          },
          signers: [wallet.publicKey],
        }
      );

      transaction.feePayer = wallet.publicKey!;
      transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

      const txId = await wallet.sendTransaction(transaction, connection);

      if (assetUploaded) assetUploaded({
        index: index + 1,
        txId: txId,
        total: poolArray.length
      });
    })
  );
}

export async function createCandyMachineV2Account(
  anchorProgram: anchor.Program,
  candyData: CandyMachineData,
  payerWallet: PublicKey,
  candyAccount: PublicKey,
) {
  const size =
    CONFIG_ARRAY_START_V2 +
    4 +
    candyData.itemsAvailable.toNumber() * CONFIG_LINE_SIZE_V2 +
    8 +
    2 * (Math.floor(candyData.itemsAvailable.toNumber() / 8) + 1);

  return anchor.web3.SystemProgram.createAccount({
    fromPubkey: payerWallet,
    newAccountPubkey: candyAccount,
    space: size,
    lamports:
      await anchorProgram.provider.connection.getMinimumBalanceForRentExemption(
        size,
      ),
    programId: CANDY_MACHINE_PROGRAM_V2_ID,
  });
}

export const createCandyMachineV2 = async function (
  connection: Connection,
  wallet: WalletContextState,
  treasuryWallet: PublicKey,
  splToken: PublicKey | null,
  candyData: CandyMachineData,
) {
  const program = await loadCandyProgramV2(wallet, connection)

  const candyAccount = Keypair.generate();
  candyData.uuid = uuidFromConfigPublicKey(candyAccount.publicKey);

  if (!candyData.symbol) {
    throw new Error(`Invalid config, there must be a symbol.`);
  }

  if (!candyData.creators || candyData.creators.length === 0) {
    throw new Error(`Invalid config, there must be at least one creator.`);
  }

  const totalShare = (candyData.creators || []).reduce(
    (acc, curr) => acc + curr.share,
    0,
  );

  if (totalShare !== 100) {
    throw new Error(`Invalid config, creators shares must add up to 100`);
  }

  const remainingAccounts = [];
  if (splToken) {
    remainingAccounts.push({
      pubkey: splToken,
      isSigner: false,
      isWritable: false,
    });
  }

  // @ts-ignore
  const transaction = await program.transaction.initializeCandyMachine(candyData, {
      accounts: {
        candyMachine: candyAccount.publicKey,
        wallet: treasuryWallet,
        authority: wallet.publicKey,
        payer: wallet.publicKey,
        systemProgram: SystemProgram.programId,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      },
      signers: [wallet.publicKey, candyAccount.publicKey],
      remainingAccounts:
        remainingAccounts.length > 0 ? remainingAccounts : undefined,
      instructions: [
        await createCandyMachineV2Account(
          program,
          candyData,
          wallet.publicKey!,
          candyAccount.publicKey,
        ),
      ],
    }
  );

  transaction.feePayer = wallet.publicKey!;
  transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

  await transaction.sign(candyAccount);

  const txId = await wallet.sendTransaction(transaction, connection, {
    signers: [candyAccount]
  });

  await awaitTransactionSignatureConfirmation(
    txId,
    parseInt(process.env.REACT_APP_SOLANA_TX_RECENT_TIMEOUT!),
    connection,
    'recent',
  );

  return {
    candyMachine: candyAccount,
    txId: txId
  };
};
