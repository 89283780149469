import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";


interface TraitsDragNDropProps {
  items: string[],
  onItemReorder: (items: string[]) => void
}

function reorder<T> (list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function TraitsDragNDrop(props: TraitsDragNDropProps) {
  return (
    <DragDropContext onDragEnd={(result: DropResult) => {
      if (!result.destination) {
        return;
      }

      props.onItemReorder(
        reorder(
          props.items,
          result.source.index,
          result.destination.index
        )
      );
    }}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div className="list-group"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >

            {props.items.map((item, index) => (
              <Draggable key={item} draggableId={item} index={index}>
                {(provided, snapshot) => (
                  <p className={`${snapshot.isDragging ? 'dragging' : 'bg-body'} border border-1 rounded py-2 mb-1`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {item}
                  </p>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
