async function toArray<T>(asyncIterator: AsyncIterableIterator<T>){
  const arr=[];
  for await(const i of asyncIterator) arr.push(i);
  return arr;
}

function dateToDateTimeLocal(date: Date): string {
  return (new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()).slice(0, -1)
}

function addMonth(date: Date, monthsToAdd: number): Date {
  return new Date(date.setMonth(date.getMonth() + monthsToAdd));
}

export {
  toArray,
  dateToDateTimeLocal,
  addMonth
}
