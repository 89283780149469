import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {getCandyMachineState} from "../lib/candyMachine/state";
import {LAMPORTS_PER_SOL, PublicKey, Transaction} from "@solana/web3.js";
import { BN } from "@project-serum/anchor";
import {getAtaForMint, toDate} from "../lib/candyMachine/utils";
import {CandyMachineAccount} from "../lib/candyMachine/types";
import {awaitTransactionSignatureConfirmation, mintOneToken} from "../lib/candyMachine/mint";
import {MintButton} from "../components/MintButton";
import { GatewayProvider } from "@civic/solana-gateway-react";
import {CANDY_MACHINE_PROGRAM_V2_ID} from "../lib/candyMachine/constants";
import { sendTransaction } from "../lib/candyMachine/sendTransaction";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {types, useAlert} from "react-alert";
import {getCandyMachine, getCandyMachineByAddress} from "../lib/supabase/candy_machine";
import {CandyMachine} from "../lib/supabase/models";
import Countdown from 'react-countdown';
import {Loader} from "../components";

export function parseDate(dateString: string) {
  if (dateString === 'now') {
    return Date.now() / 1000;
  }
  return Date.parse(dateString) / 1000;
}
interface Props {
  rpcUrl: string
}

export default function Mint(props: Props) {
  const params = useParams();
  const candyMachineId = params.candyMachineId;

  const wallet = useWallet();
  const {connection} = useConnection();

  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [isActive, setIsActive] = useState(false);
  const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [discountPrice, setDiscountPrice] = useState<BN>();
  const [candyMachineInfo, setCandyMachineInfo] = useState<CandyMachine>();
  const [isLoading, setIsLoading] = useState(true);

  const refreshCandyMachineState = useCallback(async () => {
    if (!wallet) {
      return;
    }

    if (candyMachineId) {
      try {
        const cndy = await getCandyMachineState(
          connection,
          wallet,
          new PublicKey(candyMachineId)
        );
        let active =
          cndy?.state.goLiveDate?.toNumber() < new Date().getTime() / 1000;
        let presale = false;
        // whitelist mint?
        if (cndy?.state.whitelistMintSettings) {
          // is it a presale mint?
          if (
            cndy.state.whitelistMintSettings.presale &&
            (!cndy.state.goLiveDate ||
              cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
          ) {
            presale = true;
          }
          // is there a discount?
          if (cndy.state.whitelistMintSettings.discountPrice) {
            setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
          } else {
            setDiscountPrice(undefined);
            // when presale=false and discountPrice=null, mint is restricted
            // to whitelist users only
            if (!cndy.state.whitelistMintSettings.presale) {
              cndy.state.isWhitelistOnly = true;
            }
          }
          // retrieves the whitelist token
          const mint = new PublicKey(
            cndy.state.whitelistMintSettings.mint,
          );
          const token = (await getAtaForMint(mint, wallet.publicKey!))[0];

          try {
            const balance = await connection.getTokenAccountBalance(
              token,
            );
            let valid = parseInt(balance.value.amount) > 0;
            // only whitelist the user if the balance > 0
            setIsWhitelistUser(valid);
            active = (presale && valid) || active;
          } catch (e) {
            setIsWhitelistUser(false);
            // no whitelist user, no mint
            if (cndy.state.isWhitelistOnly) {
              active = false;
            }
            console.log('There was a problem fetching whitelist token balance');
            console.log(e);
          }
        }
        // datetime to stop the mint?
        if (cndy?.state.endSettings?.endSettingType.date) {
          setEndDate(toDate(cndy.state.endSettings.number));
          if (
            cndy.state.endSettings.number.toNumber() <
            new Date().getTime() / 1000
          ) {
            active = false;
          }
        }
        // amount to stop the mint?
        if (cndy?.state.endSettings?.endSettingType.amount) {
          let limit = Math.min(
            cndy.state.endSettings.number.toNumber(),
            cndy.state.itemsAvailable,
          );
          if (cndy.state.itemsRedeemed < limit) {
            setItemsRemaining(limit - cndy.state.itemsRedeemed);
          } else {
            setItemsRemaining(0);
            cndy.state.isSoldOut = true;
          }
        } else {
          setItemsRemaining(cndy.state.itemsRemaining);
        }

        if (cndy.state.isSoldOut) {
          active = false;
        }

        setIsActive((cndy.state.isActive = active));
        setIsPresale((cndy.state.isPresale = presale));
        setCandyMachine(cndy);
      } catch (e) {
        console.log('There was a problem fetching Candy Machine state');
        console.log(e);
      }
    }
  }, [wallet, connection]);

  const alert = useAlert();

  const refreshCandyMachineInfo = useCallback(async function() {
    if (candyMachineId) {
      setCandyMachineInfo(await getCandyMachineByAddress(candyMachineId!));
    }
  }, [candyMachineId])

  useEffect(() => {
    const refresh = async () => {
      setIsLoading(true);
      await refreshCandyMachineState();
      await refreshCandyMachineInfo();
      setIsLoading(false);
    };
    refresh();
  }, [refreshCandyMachineState])

  const onMint = async (
    beforeTransactions: Transaction[] = [],
    afterTransactions: Transaction[] = [],
  ) => {
    try {
      setIsUserMinting(true);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        let mintOne = await mintOneToken(
          connection,
          wallet,
          candyMachine,
          beforeTransactions,
          afterTransactions,
        );

        const mintTxId = mintOne[0];

        let status: any = { err: true };
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            parseInt(process.env.REACT_APP_SOLANA_TX_RECENT_TIMEOUT!),
            connection,
            'recent',
          );
        }

        if (status && !status.err) {
          // manual update since the refresh might not detect
          // the change immediately
          let remaining = itemsRemaining! - 1;
          setItemsRemaining(remaining);
          setIsActive((candyMachine.state.isActive = remaining > 0));
          candyMachine.state.isSoldOut = remaining === 0;
          alert.show('Congratulations! Mint succeeded!', {type: types.SUCCESS});
          refreshCandyMachineState();
        } else {
          alert.show('Mint failed! Please try again!', {type: types.ERROR});
        }
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          console.log(error);
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          console.log(error);
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      alert.show(message, {type: types.ERROR});
      // updates the candy machine state to reflect the lastest
      // information on chain
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  function onGatewayTransaction() {
    return async (transaction: Transaction) => {
      setIsUserMinting(true);
      const userMustSign = transaction.signatures.find(sig =>
        sig.publicKey.equals(wallet.publicKey!),
      );
      if (userMustSign) {
        alert.show('Please sign one-time Civic Pass issuance', {type: types.INFO});
        try {
          transaction = await wallet.signTransaction!(
            transaction,
          );
        } catch (e) {
          alert.show('User cancelled signing', {type: types.ERROR});
          // setTimeout(() => window.location.reload(), 2000);
          setIsUserMinting(false);
          throw e;
        }
      } else {
        alert.show('Refreshing Civic Pass', {type: types.INFO});
      }
      try {
        await sendTransaction(
          connection,
          wallet,
          transaction,
          [],
          true,
          'confirmed',
        );
        alert.show('Please sign minting', {type: types.INFO});
      } catch (e) {
        alert.show('Solana dropped the transaction, please try again', {type: types.ERROR});
        console.error(e);
        // setTimeout(() => window.location.reload(), 2000);
        setIsUserMinting(false);
        throw e;
      }
      await onMint();
    };
  }

  if (isLoading) {
    return (
      <div className="container">
        <div className="row  mt-5 justify-content-center">
          <div className="col-md-6 shadow-box p-4 text-center">
            <Loader>Loading</Loader>
          </div>
        </div>
      </div>
    )
  }
  else if (!candyMachine || !candyMachineInfo) {
    return (
      <div className="container">
        <div className="row  mt-5 justify-content-center">
          <div className="col-md-6 shadow-box p-4">
            <p>Oops... We couldn't find this candy machine</p>
            <p>Here are few potential reasons: </p>
            <ul>
              <li>The link is invalid</li>
              <li>This candy machine was destroyed</li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container p-4">
        <div className="row justify-content-center" style={{marginTop: 100}}>
          <div className="col-md-9 col-lg-6 p-4 text-center bg-secondary shadow-box">
            <h3>{candyMachineInfo.nickname}</h3>

            <Countdown date={toDate(candyMachine.state.goLiveDate)} renderer={({hours, minutes, seconds, completed}) => {
              if ((isWhitelistUser && isPresale) || completed) {
                return (
                  <div>
                    <div className="row my-4">
                      <div className="col text-center">
                        <p className="h2">{candyMachine.state.itemsRemaining} / {candyMachine.state.itemsAvailable}</p>
                        <p className="fs-6 text-muted">items remaining</p>
                      </div>
                      <div className="col text-center">
                        <p className="h2">
                          <span className={isWhitelistUser && !!discountPrice ? 'text-decoration-line-through text-muted' : ''}>{candyMachine.state.price.toNumber() / LAMPORTS_PER_SOL}</span>
                          <span className={isWhitelistUser && !!discountPrice ? 'text-decoration-line-through text-muted' : ''}>◎</span>

                          {isWhitelistUser && !!discountPrice &&
                            <>
                              <span className="ms-2">{(discountPrice?.toNumber() ?? 0) / LAMPORTS_PER_SOL}</span>
                              <span>◎</span>
                            </>
                          }
                        </p>
                        <p className="fs-6 text-muted">price</p>
                      </div>
                    </div>

                    {!wallet.connected ? (
                      <WalletMultiButton>Connect Wallet</WalletMultiButton>
                    ) : (
                      <>
                        <div>
                          {candyMachine?.state.isActive &&
                          candyMachine?.state.gatekeeper &&
                          wallet.publicKey &&
                          wallet.signTransaction ? (
                            <GatewayProvider
                              wallet={{
                                publicKey:
                                  wallet.publicKey ||
                                  new PublicKey(CANDY_MACHINE_PROGRAM_V2_ID),
                                //@ts-ignore
                                signTransaction: wallet.signTransaction,
                              }}
                              gatekeeperNetwork={
                                candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                              }
                              clusterUrl={props.rpcUrl}
                              handleTransaction={onGatewayTransaction()}
                              broadcastTransaction={false}
                              options={{ autoShowModal: false }}
                            >
                              <MintButton
                                candyMachine={candyMachine}
                                isMinting={isUserMinting}
                                setIsMinting={val => setIsUserMinting(val)}
                                onMint={onMint}
                                isActive={isActive || (isPresale && isWhitelistUser)}
                              />
                            </GatewayProvider>
                          ) : (
                            <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              setIsMinting={val => setIsUserMinting(val)}
                              onMint={onMint}
                              isActive={isActive || (isPresale && isWhitelistUser)}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )
              } else {
                return (
                  <div className="row">
                    <div className="col p-4">
                      <p>
                        This mint starts at <br/>{toDate(candyMachine.state.goLiveDate)?.toLocaleString()}
                      </p>
                      <span className="fs-3">
                  🕰 {hours}h {minutes}m {seconds}s 🕰
                </span>
                    </div>
                  </div>
                )
              }
            }}/>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <div className="col-md-6 text-center">
            <p>Created with <a href="/">Sorbet</a></p>
          </div>
        </div>

      </div>
    )
  }
}
