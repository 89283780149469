import {useCallback, useEffect, useState} from "react";
import {ErrorBox, StepHeader} from "../components";
import Loader from "../components/Loader";
import {userHasArweaveWallet, updateArweaveTransaction} from "../lib/arweave";
import createMetadata from "../lib/candyMachine/upload";
import {addTransactions, getAssetsWithTransactions, getCollection} from "../lib/supabase";
import {AssetWithTransaction, Collection} from "../lib/supabase/models";
import {Link, useParams} from "react-router-dom";
import {updateTransaction} from "../lib/supabase/transaction";
import {useAlert} from "react-alert";
import {useWallet} from "@solana/wallet-adapter-react";
import _ from "lodash";

const viewBlockBaseUrl = 'https://viewblock.io/arweave/tx';
export const arweaveBaseUrl = 'https://arweave.net';

function truncateString(s: string) {
  return `${s.slice(0, 6)}..${s.slice(-4)}`
}

export default function UploadToArweave() {
  const params = useParams();
  const collectionId = parseInt(params.collectionId!);

  const [collection, setCollection] = useState<Collection>();
  const [assets, setAssets] = useState<AssetWithTransaction[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isReuploading, setisReuploading] = useState(false);
  const [hasArweaveWallet, setHasArweaveWallet] = useState(true);
  const [assetsUploaded, setAssetsUploaded] = useState(0);

  const refresh = useCallback(async function () {
    const loadedCollection = await getCollection(collectionId);
    const loadedAssets = await getAssetsWithTransactions(collectionId, false);

    setCollection(loadedCollection)
    setAssets(loadedAssets);
    setHasArweaveWallet(userHasArweaveWallet());
    setAssetsUploaded(loadedAssets
      .filter(a => a.transactions.length > 0)
      .length
    );
  }, [collectionId])

  useEffect(() => {
    refresh();
  }, [refresh])

  const alert = useAlert();
  const wallet = useWallet();

  const upload = useCallback(async () => {
    if (!wallet.connected) {
      alert.info('Please connect your wallet before uploading images');
      return;
    }

    setIsUploading(true);

    const chunkSize = 20;

    try {
      const assetsWithoutTansactions = assets
        .filter(a => a.transactions.length === 0);

      const chunks = _.chunk(assetsWithoutTansactions, chunkSize);

      let totalAssetsUploaded = assetsUploaded;

      let i = 0;
      for (const chunk of chunks) {
        console.log(`Starting to upload chunk ${i} of ${chunks.length}`);
        const results = await Promise.all(
          chunk.map(async (asset, i) => {
            try {
              const arweaveTransaction = await createMetadata(collection!, asset, wallet);
              await addTransactions([arweaveTransaction]);
              console.log(`Asset ${asset.name} succeeded`);
              return true;
            } catch (e) {
              // These requests fail all the time. Just need to try again.
              console.log(`Asset ${asset.name} failed`);
              console.log(e);
              return false;
            }
          })
        );
        const successfulUploads = results.filter(r => r).length;
        totalAssetsUploaded = assetsUploaded + successfulUploads;
        setAssetsUploaded(totalAssetsUploaded);
        i++;
      }

      if (totalAssetsUploaded === assets.length) {
        alert.success('All images uploaded');
      } else {
        alert.success('Uploaded some images, but not all of them. Please just try again. Don\'t worry, we bother with anything that\'s already been uploaded. Note that Arweave has a rate-limit which prevents us from uploading a lot of files at once. In these cases, just wait and try in a few minutes and it should work.');
      }

      await refresh();
      } catch (err) {
      console.log(err);
    } finally {
      setIsUploading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, wallet, collection, assetsUploaded]);

  const update = useCallback(async() => {
    setIsUploading(true);

    await Promise.all(assets.map(async asset => {
      if (asset.transactions.length > 0) {
        const updatedTransaction = await updateArweaveTransaction(asset.transactions[0].transaction_id!)

        if (updatedTransaction) {
          updateTransaction(asset.transactions[0].id!, updatedTransaction.Status, updatedTransaction.Confirmations,
            updatedTransaction.RefreshDate.toISOString())
        }
      }
    }))

    await refresh();

    setIsUploading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, refresh])

  const reupload = useCallback(async(asset: AssetWithTransaction) => {
    if (!wallet.connected) {
      alert.info('Please connect your wallet before uploading images');
      return;
    }

    try {
      setisReuploading(true);
      const newTransaction = await createMetadata(collection!, asset, wallet);
      await addTransactions([newTransaction]);
      await refresh();
    } catch (e) {
      console.log(e);
      alert.error('Something went wrong. Please try again.');
    } finally {
      setisReuploading(false);
      alert.success('Re-uploaded transaction.');
    }
  }, [collection, refresh, wallet]);

  return (
    <div className="container">

      <StepHeader title="Upload to Arweave"/>
      <p className="text-center">{assetsUploaded} / {assets.length} uploaded</p>

      {!hasArweaveWallet &&
          <ErrorBox title="🧐 No Arweave Wallet Found">
              <p>We couldn't find your <a href="https://www.arweave.org/">Arweave</a> wallet. Go grab one and refresh this page when you're finished.</p>
              <ul>
                  <li>If you don't have a wallet yet, we recommend downloading <a href="https://www.arconnect.io/">ArConnect</a></li>
                  <li>Arweave isn't listed on major exchanges yet. Here's a <a href="https://cryptorank.io/price/arweave/exchanges">list</a> of exchanges where you can purchase AR.</li>
              </ul>
          </ErrorBox>
      }

      <div className="row mt-4 mb-5">
        <div className="col text-center">
         {assets.length > 0 && assetsUploaded === assets.length ?
          <button type="button" className="btn btn-primary" disabled={isUploading || !hasArweaveWallet} onClick={update}>Refresh</button>
          : <button type="button" className="btn btn-primary" disabled={isUploading || !hasArweaveWallet} onClick={upload}>Upload remaining</button>
         }
        </div>
      </div>

      {isUploading &&
          <Loader className="text-center">
              <div className="row justify-content-center">
                  <div className="col-md-6">
                      <p>✨ Uploading to Arweave... ✨</p>
                      <p>This can take a while depending on this size of your collection. Leave this page open and take a break.</p>
                  </div>
              </div>
          </Loader>
      }

      {isRefreshing &&
          <Loader className="text-center">
              <div className="row justify-content-center">
                  <div className="col-md-6">
                      <p>✨ Refreshing transaction status... ✨</p>
                  </div>
              </div>
          </Loader>
      }

      {!isUploading && !isRefreshing &&
        <div className="row">
            <div className="col">
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Transaction</th>
                        <th>Raw</th>
                        <th>Confirmations</th>
                        <th>Status</th>
                        <th>Refreshed At</th>
                    </tr>
                    </thead>
                    <tbody>
                    {assets.map(asset =>
                      <tr key={asset.name}>
                        <td>{asset.name}</td>
                        <td>
                          <a href={asset.transactions.length > 0 ? `${viewBlockBaseUrl}/${asset.transactions[0].transaction_id}` : viewBlockBaseUrl}>
                            {asset.transactions.length > 0 ?
                              truncateString(asset.transactions[0].transaction_id!)
                              : null}
                          </a>
                        </td>
                        <td>
                          <a href={asset.transactions.length > 0 ? `${arweaveBaseUrl}/${asset.transactions[0].transaction_id}` : arweaveBaseUrl}>
                            {asset.transactions.length > 0 ? 'Raw' : null}
                          </a>
                        </td>
                        <td>{asset.transactions.length > 0 ? asset.transactions[0].confirmations : null}</td>
                        <td>{asset.transactions.length > 0 ? asset.transactions[0].status : null}</td>
                        <td>{asset.transactions.length > 0 ? new Date(asset.transactions[0].refreshed_at!).toLocaleString() : null}</td>
                      </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
      }
    </div>
  )
}
