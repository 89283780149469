import {Collection} from "./models";
import {definitions} from "./types";
import {supabase} from "./supabase";

export const getCollection = async (id: number): Promise<Collection | undefined> => {
  const {data} = await supabase
    .from<definitions["collections"]>("collections")
    .select("*")
    .eq('id', id)

  if (data && data?.length > 0) {
    return data![0];
  }
}

export const getCollections = async (): Promise<Collection[]> => {
  const {data} = await supabase
    .from<definitions["collections"]>("collections")
    .select("*")

  return data || [];
}

export const addCollection = async (collection: Collection) => {
  const {data} = await supabase
    .from<definitions["collections"]>("collections")
    .insert(collection);

  return data![0].id;
}

export const updateCollection = async (collection: Collection) => {
  await supabase
    .from<definitions["collections"]>("collections")
    .update(collection)
    .match({id: collection.id});
}

export const deleteCollection = async (id: number) => {
  await supabase
    .from<definitions["collections"]>("collections")
    .delete()
    .match({id: id});
}
