import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {App} from "./App";
import {AlertOptions, positions, transitions, Provider as AlertProvider} from "react-alert";
import {AlertTemplate} from "./components";

const options: AlertOptions = {
  position: positions.TOP_CENTER,
  timeout: 6000,
  offset: '0px',
  transition: transitions.SCALE,
  containerStyle: {
    marginTop: 100
  }
}

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <App/>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
