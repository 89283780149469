import {StepHeader} from "../components";
import {Field, Form, Formik, FormikErrors, useField} from "formik";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AppRoute} from "./AppRoute";
import {getCollection, updateCollection, addCollection} from "../lib/supabase";
import {Auth} from "@supabase/ui";
import {addMonth, dateToDateTimeLocal} from "../utils/utils";
import {CandyMachineAccount} from "../lib/candyMachine/types";
import {getCandyMachineState} from "../lib/candyMachine/state";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {getCandyMachine} from "../lib/supabase/candy_machine";
import {LAMPORTS_PER_SOL, PublicKey} from "@solana/web3.js";
import {toDateString} from "../lib/candyMachine/utils";
import {updateCandyMachine} from "../lib/candyMachine/update";
import {CandyMachine, Collection} from "../lib/supabase/models";
import {useAlert} from "react-alert";

interface FormValues {
  collectionFamily?: string,
  collectionName?: string,
  symbol?: string,
  description?: string,
  supply?: number,
  price?: number,
  captcha: boolean,
  goLiveDate?: string,
  sellerFeeBasisPoints?: number,
  whitelistEnabled: boolean,
  whitelistMode?: string,
  whitelistMint?: string,
  whitelistPresale: boolean,
  whitelistDiscountPrice?: number
}

function validate(values: FormValues) {
  const errors: FormikErrors<FormValues> = {};

  if (!values.collectionFamily) errors.collectionFamily = 'Required';
  if (!values.collectionName) errors.collectionName = 'Required';
  if (!values.price) errors.price = 'Required';
  if (!values.symbol) errors.symbol = 'Required';
  if (!values.supply) errors.supply = 'Required';
  if (!values.goLiveDate) errors.goLiveDate = 'Required';
  if (!values.sellerFeeBasisPoints && values.sellerFeeBasisPoints !== 0) errors.sellerFeeBasisPoints = 'Required';

  if (values.price && values.price < 0) {
    errors.price = 'Price must be positive';
  }

  if (values.supply && values.supply < 1) {
    errors.supply = 'Must have a supply of at least one';
  }

  if (values.supply && values.supply > 10000) {
    errors.supply = 'Sorbet only supports collections of 10,000 or less for now';
  }

  if (values.sellerFeeBasisPoints && (values.sellerFeeBasisPoints < 0 || values.sellerFeeBasisPoints > 10000)) {
    errors.sellerFeeBasisPoints = 'Must be value between 200 and 10,000';
  }

  if (values.whitelistEnabled) {
    if (!values.whitelistMint) errors.whitelistMint = 'Required';
  }

  return errors;
}
export function ConfigureCandyMachine() {
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    collectionFamily: "",
    collectionName: "",
    symbol: "",
    description: "",
    supply: undefined,
    price: undefined,
    captcha: false,
    goLiveDate: dateToDateTimeLocal(addMonth(new Date(), 1)),
    sellerFeeBasisPoints: undefined,
    whitelistEnabled: false,
    whitelistMode: 'Burn every time',
    whitelistMint: undefined,
    whitelistPresale: false,
    whitelistDiscountPrice: undefined
  })
  const [candyMachineState, setCandyMachineState] = useState<CandyMachineAccount>();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();
  const {connection} = useConnection();
  const wallet = useWallet();

  const params = useParams();
  const collectionId = parseInt(params.collectionId!) > 0 ?
    parseInt(params.collectionId!)
    : null;

  const alert = useAlert();

  const refresh = useCallback(async function() {
    if (collectionId) {
      const collection = await getCollection(collectionId);
      const loadedCandyMachine = await getCandyMachine(collectionId);
      if (collection) {
        setInitialFormValues({
          collectionFamily: collection.family,
          collectionName: collection.name,
          symbol: collection.symbol,
          description: collection.description,
          supply: collection.supply,
          price: collection.price,
          captcha: collection.captcha_enabled,
          goLiveDate: dateToDateTimeLocal(new Date(collection.go_live_date)),
          sellerFeeBasisPoints: collection.seller_fee_basis_points,
          whitelistEnabled: collection.whitelist_enabled,
          whitelistMode: collection.whitelist_mode,
          whitelistMint: collection.whitelist_mint,
          whitelistPresale: collection.whitelist_presale,
          whitelistDiscountPrice: collection.whitelist_discount_price
        });
      }

      if (loadedCandyMachine) {
        setCandyMachine(loadedCandyMachine);
        const cmState = await getCandyMachineState(connection, wallet, new PublicKey(loadedCandyMachine.address));
        setCandyMachineState(cmState);
      }
    }
  }, [collectionId])

  useEffect(() => {
    refresh()
  }, [collectionId])

  interface TextFormInputProps {
    label: string,
    name: string,
    type: string,
    placeholder?: string
    description: string
    disabled?: boolean
  }

  function TextFormInput(props: TextFormInputProps) {
    const [field, meta] = useField(props);
    return (
      <div className="form-group">
        <label htmlFor={props.name}>{props.label}</label>
        <input type={props.type}
               className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
               id={props.name}
               aria-describedby={`${props.name}Description`}
               placeholder={props.placeholder}
               disabled={props.disabled}
               {...field}
        />
        {meta.touched && meta.error ?
          <div className="invalid-feedback">{meta.error}</div>
          : null}
        <p id={`${props.name}Description`} className="form-text text-muted">
          {props.description}
        </p>
      </div>
    );
  }

  interface TextAreaFormInputProps {
    label: string,
    name: string,
    placeholder?: string
    description: string,
    rows?: number
    disabled?: boolean
  }

  function TextAreaFormInput(props: TextAreaFormInputProps) {
    const [field, meta] = useField(props);
    return (
      <div className="form-group">
        <label htmlFor={props.name}>{props.label}</label>
        <textarea rows={props.rows}
                  className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                  id={props.name}
                  aria-describedby={`${props.name}Description`}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                  {...field}
        />
        {meta.touched && meta.error ?
          <div className="invalid-feedback">{meta.error}</div>
          : null}
        <p id={`${props.name}Description`} className="form-text text-muted">
          {props.description}
        </p>
      </div>
    );
  }

  interface CheckboxFormInputProps {
    label: string,
    name: string,
    children: ReactNode
  }

  function CheckboxFormInput(props: CheckboxFormInputProps) {
    const [_, meta] = useField(props);
    return (
      <div className="form-group">
        <Field type="checkbox"
               name={props.name}
               className={`form-check-input me-2 ${meta.touched && meta.error ? 'is-invalid' : ''}`}
               id={props.name}
               aria-describedby={`${props.name}Description`}
        />
        <label className="form-check-label" htmlFor={props.name}>{props.label}</label>
        {meta.touched && meta.error ?
          <div className="invalid-feedback">{meta.error}</div>
          : null}
        <p id={`${props.name}Description`} className="form-text text-muted">
          {props.children}
        </p>
      </div>
    );
  }

  interface RadioGroupInputProps {
    label: string,
    name: string,
    options: {
      name: string,
      description: string
    }[]
  }

  function RadioGroupInput(props: RadioGroupInputProps) {
    const [field, meta] = useField(props);
    return <>
      <label htmlFor={`radioGroup-${props.name}`}>{props.label}</label>
      <div className="form-control-plaintext" id={`radioGroup-${props.name}`}>
        {props.options.map((o, i) =>
          <div key={o.name.replace(' ', '')} className="form-check">
            <Field className="form-check-input" type="radio" id={o.name.replace(' ', '')}
                   name={props.name} value={o.name}/>
            <label className="form-check-label" htmlFor={o.name.replace(' ', '')}>
              {o.name}
            </label>
            {meta.touched && meta.error ?
              <div className="invalid-feedback">{meta.error}</div>
              : null}
            <p className="form-text text-muted">
              {o.description}
            </p>
          </div>
        )}
      </div>
    </>;
  }

  const navigate = useNavigate();
  const { user } = Auth.useUser()

  async function onSubmit(values: FormValues) {
    if (collectionId) {
      const collection: Collection = {
        id: collectionId,
        name: values.collectionName!,
        user_id: user!.id,
        family: values.collectionFamily!,
        symbol: values.symbol!,
        description: values.description,
        supply: values.supply!,
        price: values.price!,
        captcha_enabled: values.captcha,
        go_live_date: new Date(values.goLiveDate!).toISOString(),
        seller_fee_basis_points: values.sellerFeeBasisPoints!,
        whitelist_enabled: values.whitelistEnabled,
        whitelist_mode: values.whitelistMode,
        whitelist_mint: values.whitelistMint,
        whitelist_presale: values.whitelistPresale,
        whitelist_discount_price: values.whitelistDiscountPrice
      };

      if (candyMachine) {
        if (!wallet.connected) alert.info('Please connect your wallet to continue');
        await updateCandyMachine(connection, wallet, collection, new PublicKey(candyMachine.address));
      }

      await updateCollection(collection);
      alert.success('Updated candy machine')
      refresh();
    } else {
      const newCollectionId = await addCollection({
        name: values.collectionName!,
        user_id: user!.id,
        family: values.collectionFamily!,
        symbol: values.symbol!,
        description: values.description,
        supply: values.supply!,
        price: values.price!,
        captcha_enabled: values.captcha,
        go_live_date: new Date(values.goLiveDate!).toISOString(),
        seller_fee_basis_points: values.sellerFeeBasisPoints!,
        whitelist_enabled: values.whitelistEnabled,
        whitelist_mode: values.whitelistMode,
        whitelist_mint: values.whitelistMint,
        whitelist_presale: values.whitelistPresale,
        whitelist_discount_price: values.whitelistDiscountPrice
      });
      navigate(`${AppRoute.Collections}/${newCollectionId}`);
    }
  }

  return (
    <div className="container mb-5">
      <StepHeader title="Configuration">
      </StepHeader>
      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({values}) =>
          <Form className="my-3 p-3">

            <fieldset>
              <legend className="border-2 border-bottom border-secondary">
                <h3>Collection</h3>

                <p className="fs-6">
                  General details about your collection. This information will be included in every NFT minted from the
                  candy machine.
                </p>
              </legend>

              <div className="form-control-plaintext">
                <TextFormInput name="collectionFamily" type="text" label="Family" placeholder="Solana Monkey Business"
                               disabled={!!candyMachine}
                               description="The collection family. This can be used to group multiple collection together (eg. gen1, gen2)" />
              </div>

              <div className="form-control-plaintext">
                <TextFormInput name="collectionName" type="text" label="Name" placeholder="Gen1" disabled={!!candyMachine}
                               description="The collection name by which NFTs will be grouped in users' wallets" />
              </div>

              <div className="form-control-plaintext">
                <TextFormInput name="symbol" type="text" label="Symbol" placeholder="SMB" disabled={!!candyMachine}
                               description="The symbol representing the collection" />
              </div>

              <div className="form-control-plaintext">
                <TextAreaFormInput name="description" rows={5} label="Description" disabled={!!candyMachine}
                                   placeholder="SMB is a collection of 5000 unique randomly generated Solana Monkeys stored on the blockchain. With their accessibility-oriented design, the monkeys' goal is to invade the Solana blockchain with as many individuals as possible, building a large community around them, supported by owner-exclusive advantages, a community wallet and a future voting system. Reject humanity, return to monke."
                                   description="An overall description of the collection" />
              </div>
            </fieldset>

            {candyMachineState &&
                <div>
                    <h3>Live Candy Machine State</h3>
                    <pre className="bg-dark text-light p-2 rounded">
                    <code>
                      {JSON.stringify({
                        id: candyMachineState.id.toBase58(),
                        supply: candyMachineState.state.itemsAvailable,
                        price: candyMachineState.state.price.toNumber() / LAMPORTS_PER_SOL,
                        captcha: !!candyMachineState.state.gatekeeper,
                        mintDate: toDateString(candyMachineState.state.goLiveDate),
                        whitelist: {
                          ...candyMachineState.state.whitelistMintSettings,
                          discountPrice: (candyMachineState.state.whitelistMintSettings?.discountPrice?.toNumber() ?? 0) / LAMPORTS_PER_SOL
                        }
                      }, null, 2)}
                    </code>
                  </pre>
                </div>
            }

            <fieldset className="mt-4">
              <legend className="border-2 border-bottom border-secondary">
                <h3>Candy machine</h3>

                <p className="fs-6">
                  Configure the operation your the candy machine.
                </p>
              </legend>

              <div className="form-control-plaintext">
                <TextFormInput name="supply" type="number" label="Supply" placeholder="150" disabled={!!candyMachine}
                               description="The number of NFTs to be minted before the candy machine shuts down" />
              </div>

              <div className="form-control-plaintext">
                <TextFormInput name="price" type="number" label="Price" placeholder="1.5"
                               description="The price of each NFT in SOL" />
              </div>

              <div className="form-control-plaintext">
                <TextFormInput name="sellerFeeBasisPoints" type="number" label="Royalty" placeholder="500 (5%)" disabled={!!candyMachine}
                               description="Royalty on secondary sales specified in basis points (0 - 10,000)" />
              </div>

              <div className="form-control-plaintext">
                <CheckboxFormInput name="whitelistEnabled" label="Whitelist">
                  Check to enable whitelist
                </CheckboxFormInput>
              </div>

              {values.whitelistEnabled &&
                  <div className="mb-4 p-3 shadow-box">
                      <div className="form-control-plaintext">
                          <TextFormInput name="whitelistMint" type="text" label="Token Mint"
                                         description="Mint address of the whitelist token"/>
                      </div>

                      <RadioGroupInput label="Mode" name="whitelistMode" options={[
                        {
                          name: "Burn every time",
                          description: "The whitelist token will be burned on mint. 1 whitelist token = 1 NFT"
                        },
                        {
                          name: "Never burn",
                          description: "The whitelist token may be used to mint as many NFTs as the user wants"
                        }
                      ]} />

                      <div className="form-control-plaintext">
                          <CheckboxFormInput name="whitelistPresale" label="Presale">
                            Check to allow whitelist token holders to mint before mint date (presale)
                          </CheckboxFormInput>
                      </div>

                      <div className="form-control-plaintext">
                          <TextFormInput name="whitelistDiscountPrice" type="number" label="Discount price"
                                         description="Price for whitelist token holders in SOL. Leave blank for no discount." />
                      </div>
                  </div>
              }

              <div className="form-control-plaintext">
                <CheckboxFormInput name="captcha" label="Civic Pass">
                  <>
                    Check to enable <a href="https://www.civic.com/solutions/civic-pass-for-nfts/">Civic Pass</a> user verification
                  </>
                </CheckboxFormInput>
              </div>

              <div className="form-control-plaintext">
                <TextFormInput label="Mint Date" name="goLiveDate" type="datetime-local"
                               description="The candy machine will not allow minting until the specified date and time. This is specified in your local time." />
              </div>
            </fieldset>

            <div className="flex-column text-center">
              <button className="mt-2 btn btn-primary" type="submit">
                Submit
              </button>
            </div>

          </Form>
        }
      </Formik>
    </div>
  )
}
