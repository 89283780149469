import {Trait} from "../supabase/models";

export default function (traitImages: Trait[]) {
  const allowedDeviation = .02;
  const expectedValue = 1;
  const maxValue = expectedValue + allowedDeviation;
  const minValue = expectedValue - allowedDeviation;

  const raritySum = traitImages.reduce((sum, {rarity}) => sum + rarity, 0);

  return raritySum <= maxValue && raritySum >= minValue;
}
