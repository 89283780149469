import {supabase} from "./supabase";
import {FileObject} from "@supabase/storage-js";

export async function createBucket(bucketId: string, overwriteIfExists: boolean = true) {
  const {data} = await supabase
    .storage
    .getBucket(bucketId);

  if (overwriteIfExists && data) {
    await emptyBucket(bucketId);

    await supabase
      .storage
      .deleteBucket(bucketId);
  }

  await supabase
    .storage
    .createBucket(bucketId);
}

// This function is needed because the built-in `emptyBucket` API does not work for buckets with many objects
async function emptyBucket(bucketId: string, chunkSize: number = 500) {
  let objects: FileObject[] | null = null;
  do {
    let {data} = await supabase
      .storage
      .from(bucketId)
      .list(undefined, {limit: chunkSize});
    objects = data;

    if (objects && objects.length > 0) {
      await supabase
        .storage
        .from(bucketId)
        .remove(objects!.map(o => o.name));
    }
  } while (objects && objects.length > 0);
}

export function getAssetBucketId(collectionId: number) {
  const bucketId = `asset-${collectionId}-traits`;
  return bucketId;
}
