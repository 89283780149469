import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {AssetImage, CandyMachineConfiguration, TraitImage, TraitsConfiguration} from '../types'
import type { RootState } from './store';

interface AppState {
  candyMachineConfiguration?: CandyMachineConfiguration,
  assets: AssetImage[],
  traitImages: TraitImage[],
  traitsConfig: TraitsConfiguration,
}

const initialState: AppState = {
  assets: [],
  traitImages: [],
  traitsConfig: {
    name: '',
    symbol: '',
    description: '',
    creators: [],
    collection: {},
    breakdown: [],
    order: [],
    width: 1000,
    height: 1000,
    seller_fee_basis_points: 0
  }
}

export const appSlice = createSlice({
  name: 'app',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCandyMachineConfiguration: (state, action: PayloadAction<CandyMachineConfiguration>)  => {
      state.candyMachineConfiguration = action.payload;
    },
    setAssets: (state, action: PayloadAction<AssetImage[]>) => {
      state.assets.map(a => {
        if (a.DataUrl) { URL.revokeObjectURL(a.DataUrl); }
        return a;
      });
      state.assets = action.payload;
    },
    setTraitImages: (state, action: PayloadAction<TraitImage[]>) => {
      state.traitImages.map(t => URL.revokeObjectURL(t.DataUrl));
      state.traitImages = action.payload;
    },
    setTraitsConfig: (state, action: PayloadAction<TraitsConfiguration>) => {
      state.traitsConfig = action.payload;
    }
  }
})

export const { setCandyMachineConfiguration, setAssets, setTraitImages, setTraitsConfig } = appSlice.actions;
export const selectCandyMachineConfiguration = (state: RootState) => state.app.candyMachineConfiguration!;
export const selectAssets = (state: RootState) => state.app.assets;
export const selectTraitImages = (state: RootState) => state.app.traitImages;
export const selectTraitsConfig = (state: RootState) => state.app.traitsConfig;

export default appSlice.reducer
