import React from "react";

type Props = {
  title: string,
  children?: React.ReactNode
}

export default function ErrorBox(props: Props) {
  return (
    <div className="row mt-4 mb-5 justify-content-center">
      <div className="col-6 border border-2 border-dark rounded shadow-box p-4">
        <h3 className="mb-4">{props.title}</h3>
        {props.children}
      </div>
    </div>
  );
}
