import {getAssetsWithTransactions, getCollection, getTraits} from "../lib/supabase";
import {useCallback, useEffect, useState} from "react";
import {AssetWithTransaction, CandyMachine, Collection, Trait, Transaction} from "../lib/supabase/models";
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppRoute} from "./AppRoute";
import {getCandyMachine} from "../lib/supabase/candy_machine";
import {confirm, CopyToClipboard, Loader} from "../components";
import _ from "lodash";
import {createSorbetCandyMachine, uploadToCandyMachine} from "../lib/candyMachine";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {CandyMachineAccount} from "../lib/candyMachine/types";
import {getCandyMachineState} from "../lib/candyMachine/state";
import {PublicKey} from "@solana/web3.js";
import {withdraw} from "../lib/candyMachine/withdraw";
import {deleteCollection} from "../lib/supabase/collection";
import {useAlert} from "react-alert";

export function CollectionDetails() {

  const [collection, setCollection] = useState<Collection>();
  const [traits, setTraits] = useState<Trait[]>();
  const [assets, setAssets] = useState<AssetWithTransaction[]>();
  const [transactions, setTransactions] = useState<Transaction[]>();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();
  const [candyMachineState, setCandyMachineState] = useState<CandyMachineAccount>()
  const [candyMachineLoadingMessage, setCandyMachineLoadingMessage] = useState<string>();
  const params = useParams();
  const collectionId = parseInt(params.collectionId!);
  const wallet = useWallet();
  const {connection} = useConnection();
  const navigate = useNavigate();
  const alert = useAlert();

  const refresh = useCallback(async function () {
    const loadedCollection = await getCollection(collectionId);
    const loadedTraits = await getTraits(collectionId, false);
    const loadedAssets = await getAssetsWithTransactions(collectionId, false);
    const loadedCandyMachine = await getCandyMachine(collectionId);

    setCollection(loadedCollection);
    if (loadedTraits) setTraits(loadedTraits);
    if (loadedAssets) {
      setAssets(loadedAssets);
      setTransactions(loadedAssets
        .filter(a => a.transactions.length > 0)
        .map(a => a.transactions[0])
      )
    }
    if (loadedCandyMachine) {
      setCandyMachine(loadedCandyMachine);
      const loadedCandyMachineState = await getCandyMachineState(connection, wallet, new PublicKey(loadedCandyMachine.address))
      setCandyMachineState(loadedCandyMachineState);
    }
  }, [collectionId])

  useEffect(() => {
    refresh();
  }, [collectionId]);

  const canUploadTraits = useCallback(function () {
    return !candyMachine
  }, [candyMachine]);

  const canGenerateImages = useCallback(function () {
    return !candyMachine && traits && traits.length > 0;
  }, [candyMachine, traits]);

  const canUploadToArweave = useCallback(function () {
    return !candyMachine
      && collection && assets && transactions
      && assets.length === collection.supply;
  }, [candyMachine, collection, assets, transactions])

  const generateIsCompleteText = useCallback(function() {
    if (collection
      && (assets?.length ?? 0) === collection.supply
      && (assets?.length ?? 0 > 0))
    {
      return `✅ ${assets?.length} / ${collection.supply} images`;
    }
    else if (collection
      && (assets?.length ?? 0) !== collection.supply
      && (assets?.length ?? 0 > 0)) {
      return `❌ ${assets?.length} / ${collection.supply} images`;
    }
    else {
      return '❌ No images';
    }
  }, [collection, assets])

  const uploadCompleteText = useCallback(function () {
    if ((transactions?.length ?? 0) === 0) {
      return '❌ No uploads';
    }
    else if ((assets?.length ?? 0) === (transactions?.length ?? 0) && (assets?.length ?? 0 > 0))
    {
      return `✅ ${transactions?.length} / ${assets?.length ?? 0} Uploaded`;
    }
    else {
      return `❌ ${transactions?.length} / ${assets?.length ?? 0} Uploaded`;
    }
  }, [assets, transactions]);

  const canInitializeCandyMachine = useCallback(function () {
    return collection && assets && transactions
      && assets.length === collection.supply
      && assets.length === transactions.length;
  }, [collection, assets, transactions]);

  async function initializeCandyMachine() {
    if (wallet && wallet.connected && collection) {
      try {
        setCandyMachineLoadingMessage("Initializing candy machine");
        const {candyMachine} = await createSorbetCandyMachine(connection, wallet, collection);

        setCandyMachineLoadingMessage("Loading images into candy machine");
        await uploadToCandyMachine(connection, wallet, collection, assets!, candyMachine);
      } finally {
        setCandyMachineLoadingMessage(undefined);
      }
    } else {
      alert.info('Connect your wallet to continue');
    }

    await refresh();
  }

  async function onDeleteCollection() {
    let collectionMessage = 'This is an irreversible action that will destroy all data associated with your collection.';
    let candyMachineMessage = null;
    if (candyMachine) {
      candyMachineMessage = ' Funds remaining in the candy machine will be withdrawn for you. That will destroy the candy machine. It cannot be recovered.';
    }
    confirm(alert, {
      message: (
        <div>
          <p>{collectionMessage}</p>
          {candyMachineMessage && <p>{candyMachineMessage}</p>}
        </div>
      ),
      cancelText: 'Nevermind',
      confirmText: 'Delete',
      onConfirm: async () => {
        if (candyMachine) {
          try {
            setCandyMachineLoadingMessage('Deleting candy machine');
            await withdraw(connection, wallet, candyMachine!.address);
          } finally {
            setCandyMachineLoadingMessage(undefined);
          }
        }

        await deleteCollection(collectionId);

        navigate(AppRoute.Collections);
      }
    });
  }

  function getCollectionDetail(c: Collection) {
    return (
      <div className="container p-4">
        <h1 className="text-center">{collection!.name}</h1>
        <p className="text-center text-muted">{collection!.family}</p>

        <div className="row mb-3 justify-content-center">
          <div className="col text-end">
            <Link to={AppRoute.ConfigureCandyMachine}>
              <button type="button" className="btn btn-light">
                <i className="bi-pencil"/> Edit
              </button>
            </Link>
          </div>
          <div className="col">
            <button type="button" className="btn btn-danger" onClick={onDeleteCollection}>
              <i className="bi-trash"/> Delete
            </button>
          </div>
        </div>

        <div className="">
          <div className="row mb-3 justify-content-center py-3">
            <div className="col-md-3 col-sm-6 text-center mb-sm-2">
              <span className="h2">{collection!.supply}</span>
              <span className="text-muted"> supply</span>
            </div>
            <div className="col-md-3 col-sm-6 text-center">
              <span className="h2">{collection!.price}</span>
              <span className="h4">◎</span>
              <span className="text-muted"> price</span>
            </div>
            <div className="col-md-3 col-sm-6 text-center">
              <span className="h2">{collection!.symbol}</span>
              <span className="text-muted"> symbol</span>
            </div>
            <div className="col-md-3 col-sm-6 text-center">
              <span className="h2">{`${collection!.captcha_enabled ? '✅' : '❌'}`}</span>
              <span className="text-muted"> captcha</span>
            </div>
          </div>

          {candyMachineLoadingMessage ?
            <Loader className="text-center">
              <p>{candyMachineLoadingMessage}</p>
            </Loader>
            :
            <div className="row mb-3 justify-content-center py-3">
              <div className="col text-center">
                <p className="h4">{new Date(collection!.go_live_date).toLocaleDateString()}
                </p>
                <p className="h5">
                  {new Date(collection!.go_live_date).toLocaleTimeString()}
                </p>
                <p className="text-muted"> mint date</p>
              </div>
            </div>
          }

        </div>

        {candyMachine &&
            <div className="row justify-content-evenly">
              <div className="col-lg-11 shadow-box p-4 text-center">
                <div className="row mb-2">
                  <div className="col">
                    <h2>Candy Machine</h2>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <CopyToClipboard
                      text={candyMachine.address}
                      hoverTooltip="Copy candy machine ID"
                      copyTooltip={"Copied to clipboard ✅"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <div className="my-3">
                      <span className="h2">{candyMachineState?.state.itemsRemaining} / {candyMachineState?.state.itemsAvailable}</span>
                      <span className="text-muted"> items remaining</span>
                    </div>
                    <Link to={`/${AppRoute.Mint}/${candyMachine?.address}`}>
                      <button type="button" className="btn btn-light">
                        Mint site
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
        }

        <div className="row justify-content-evenly mt-5">
          <div className="col-lg-5 text-center shadow-box p-4">
            <h2>{`${traits?.length ?? 0 > 0 ? `✅ ${traits?.length} Traits` : '❌ No Traits'}`}</h2>

            {traits && traits?.length > 0 &&
                <table className="table table-striped table-sm fs-6 text-start border border-2 border-dark">
                    <thead className="table-active">
                    <tr>
                        <th>Trait Type</th>
                        <th>Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(_.groupBy(traits, t => t.type)).map(key =>
                      <tr key={key}>
                        <td key={`${key}_type`}>{key}</td>
                        <td key={`${key}_count`}>{traits?.filter(t => t.type === key).length}</td>
                      </tr>
                    )}
                    </tbody>
                </table>
            }
            <div className="row mb-2">
              <div className="col text-center">
                <Link to={AppRoute.UploadTraits} style={!canUploadTraits() ? {pointerEvents: 'none'} : {}}>
                  <button type="button" className={`${canUploadTraits() ? '' : 'disabled'} btn btn-light`}>
                    Edit traits
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-5 shadow-box p-4 mt-5 mt-lg-0">
            <div className="row mb-2">
              <div className="col text-center">
                <h2>{generateIsCompleteText()}</h2>
                <Link to={AppRoute.GenerateImages} style={!canGenerateImages() ? {pointerEvents: 'none'} : {}}>
                  <button type="button" className={`${canGenerateImages() ? '' : 'disabled'} btn btn-light`}>
                    Generate images
                  </button>
                </Link>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col text-center">
                <h2>{uploadCompleteText()}</h2>
                <Link to={AppRoute.UploadToArweave} style={!canUploadToArweave() ? {pointerEvents: 'none'} : {}}>
                  <button type="button" className={`${canUploadToArweave() ? '' : 'disabled'} btn btn-light`}>
                    Upload to Arweave
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {!candyMachine &&
          <div className="row mt-5">
              <div className="col text-center">
                  <button type="button" className={`${canInitializeCandyMachine() ? '' : 'disabled'} btn btn-primary`} onClick={initializeCandyMachine}>
                      🍬 Initialize candy machine 🍬
                  </button>
              </div>
          </div>
        }

      </div>
    );
  }

  return (
    <div className="container mb-5">
      {collection ?
        getCollectionDetail(collection)
        :
        <div className="row text-center">
          <Loader/>
        </div>
      }
    </div>
  )
}
