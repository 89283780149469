export enum AppRoute {
  Login = '/login',
  Collections = '/collections',
  ConfigureCandyMachine = 'configure',
  CreateCandyMachine = 'new',
  UploadTraits = 'traits',
  GenerateImages = 'images',
  UploadToArweave = 'upload',
  InitializeCandyMachine = 'initialize',
  Mint = 'mint'
}
